// OrderTrackingPage.jsx
import React, { useState, useEffect } from 'react';
import MapComponent from '../widgets/mapTrack'; // Importing the MapComponent
import '../css/OrderTrackingPage.css'; // Import the CSS for styling
import { doc, getDoc , onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';
import { ReadAddress, readOrderStatus, readTimestamp, readTimestampN } from '../constants/constants';
import OrderProgressBar from '../widgets/orderProgress';
import { Drawer, Divider,
  Box,  Typography, Button, 
  IconButton, List, ListItem, Avatar,ListItemText,ListItemAvatar
} from '@mui/material';
import success from '../asset/success.png'
import CloseIcon from '@mui/icons-material/Close';
import { readPackageAddons } from '../business/functions';

const OrderTrackingPage = ({orderId  , orderModel, closeOrder}) => {
  const [orderStatus, setOrderStatus] = useState('In Transit');
  const [orderLocation, setOrderLocation] = useState({ lat: 40.7128, lng: -74.0060 });
  
  const [paymentMethod, setPaymentMethod] = useState('MasterCard');
  const [activeOrderModel, setActiveOrderModel] = useState(orderModel);
  const [lastFourDigits, setLastFourDigits] = useState('8761');
  const [hasPermission, setHasPermission] = useState(true);

  const [loading, setLoading] = useState(true);
  const [hasMap, setHasMap] = useState(false);

  const setOrderModelContent = async () => {
    console.log('setOrderModelContent');
    
    if (!orderModel) {
        if ( orderId ) {
            console.log('orderId');
            try {
            const unsubscribe = onSnapshot(doc(firestore, 'orders', orderId), (orderData) => {
              if (orderData.exists()) {
                console.log('orderData.exists');
                const order = orderData.data();
                setLoading(false);
                setActiveOrderModel(order);
                if ( order.orderType == "Deliver") {
                    console.log('Deliver');
                    const orderLocation = 
                        {lat:order?.position?.geopoint?.latitude??30, 
                        lng:order?.position?.geopoint?.longitude??30,}
                    setOrderLocation(orderLocation);
                    setHasMap(true);
                  }
              } 
            }, (error) => {
              unsubscribe(); // Unsubscribe in case of an error
              setHasPermission(false);
               // Reject the promise if there's an error
            });
           } catch{ 
            console.log('error snapshot')
           }
          }
        
    }
    
    if ( orderModel ) {
        

    }
  }
  useEffect(() => {
    setOrderModelContent();
    // Example: Fetch current location, order status, and items here
    // setOrderLocation({ lat: newLat, lng: newLng });
    // setOrderStatus('Delivered');
    // You can use WebSockets or polling here for real-time updates
  }, []);


if (!hasPermission) return (<div>No permission</div>);
  // Order Details Component
  const OrderDetails = ({ status }) => {
    return (
      <div className="order-details">
        <h2>{activeOrderModel?.orderType??""} 
          #{activeOrderModel?.orderNum??""}-{activeOrderModel?.orderDailyNum??""}
          </h2>
          <div style={{
            paddingRight: '12px',
            paddingLeft: '12px',
            flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'
            }}>
          <p>Status: <span className="status">{readOrderStatus(activeOrderModel?.status??0)}</span></p>
        
          {activeOrderModel?.status == 7 &&(<img 
              width={20} 
              src={success} 
              alt="Logo" 
              style={{ 
                marginRight: '10px', 
                borderRadius: '10%'  // Circular image
              }} 
            />)}
          </div>
        
        {activeOrderModel?.active == true 
        || activeOrderModel?.status == 7 ?
        (<OrderProgressBar orderModel={activeOrderModel} percentage={(activeOrderModel?.status??4)/7*100}   />): null }
        <div className="details">
          <p>Pickup Point: {ReadAddress(activeOrderModel?.storeAddressO??{})}</p>
          <p>Delivery Point: {ReadAddress(activeOrderModel?.cstAddressO??{})}</p>
          <p>Order time: {readTimestamp(activeOrderModel?.scheduledOn)}</p>
          {/* <p>arrive time: </p> */}
          
        </div>
      </div>
    );
  };

  // Courier Info Component
  const CourierInfo = () => {
    return activeOrderModel?.orderType == "Deliver" && 
    activeOrderModel?.onlineDelivery == true ?
    (
      <div className="courier-info">
        <h3>Deliver by</h3>
        <div className="courier-details">
            {/* <p>Delivery</p> */}
          <p>{activeOrderModel?.driverName??""}</p>
          
          {/* <p>Phone: {contactInfo.phone}</p> */}
          {/* <p>Email: {contactInfo.email}</p> */}
        </div>
      </div>
    ):null;
  };

  // Payment Method Component
  const PaymentMethod = ({ method, lastFourDigits }) => {
    return (
      <div className="payment-method">
        {/* <h3>Payment Method</h3>
        <div className="payment-details">
          <p>{method} ****{lastFourDigits}</p>
          <button>Change</button>
        </div> */}
      </div>
    );
  };

  const subTotal = (activeOrderModel?.status <=1 ?( activeOrderModel.productsOL) : (activeOrderModel?.pickedProductsOL??[])).reduce((acc, item) => acc + item.price * item.quantity, 0);

  const feesTotal = (activeOrderModel?.orderFeesOL??[] )?.reduce((acc, item) => acc + item.cost , 0);

  const total = subTotal + (activeOrderModel?.deliveryPrice??0) + feesTotal;

  // Order Items List Component
  const OrderItemsList = ({ items }) => {
    
    return (
      <div className="order-items-list">
        <h3>Order Items</h3>
        
        {items.map((item, index) => (
          <div key={index}>
           <ListItem>
              {/* Product Image */}
              {(item.img??"")!==""?(<ListItemAvatar>
                <Avatar
                  variant="rounded"
                  src={item.img}
                  alt={item.nameT['en']}
                  sx={{ width: 56, height: 56 }}
                />
              </ListItemAvatar>):null}

              {/* Product Name and Price */}
              <ListItemText
                primary={item.quantity + " "+ item.nameT['en']}
                secondary={
                  <>
                  {readPackageAddons(item?.addOnOptionsL)}
                  <br />
                  {item.note}
                </>

                }
                sx={{ marginLeft: '10px' }}
              />

              {/* Quantity Controls */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '12px',
                }}
              >
                <Typography sx={{ padding: '0 10px' }}>
                  {`${(item.price/100)*item.quantity}`}</Typography>

              </Box>
            </ListItem>
            
          </div>
        ))}
        <Divider /> 
        <div style={{padding:"15px"}}>
            <Box 
                sx={{ padding:"5px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="h7"
                  style={{
                    fontWeight:'bold',
                    color: "black"}}
                  >Subtotal</Typography>
                  <Typography 
                  style={{
                    fontWeight:'bold',
                  }}
                  variant="h7">
                    {subTotal/100}
                    </Typography>
                </Box>
                {activeOrderModel?.orderType == "Deliver" &&(<Box 
        sx={{ padding:"5px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h7">Delivery </Typography>
          <Typography variant="h7">{(activeOrderModel?.deliveryPrice??0)/100} </Typography>
        </Box>)}
        {(activeOrderModel?.orderFeesOL??[]).map((feeO, indexS) => (
              <Box key={indexS}
                sx={{ padding:"5px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="h7"
                  style={{
                    fontWeight:'bold',
                    color:feeO.cost<0 ? "red":"black"}}
                  >{feeO?.name??""}</Typography>
                  <Typography 
                  style={{
                    fontWeight:'bold',
                  }}
                  variant="h7">{(feeO?.cost??0)/100} {" "}</Typography>
                </Box>
            ))}
            <Box 
                sx={{ padding:"5px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="h7"
                  style={{
                    fontWeight:'bold',
                    color: "black"}}
                  >Total</Typography>
                  <Typography 
                  style={{
                    fontWeight:'bold',
                  }}
                  variant="h7">
                    {total/100}
                    </Typography>
                </Box>
        </div>
        

      </div>
    );
  };

  return (
    <div className="order-tracking-page">
      <IconButton
        onClick={closeOrder}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}
        aria-label="close"
      >
        <CloseIcon fontSize="large" />

      </IconButton> 
      {hasMap && (<MapComponent location={orderLocation} />)}
      {!loading && (<div className="order-details-container">
      
        <OrderDetails status={orderStatus} />
        <CourierInfo name="Johnathan Hutchinson" contactInfo={{ phone: '123-456-7890', email: 'example@example.com' }} />
        <OrderItemsList 
        items={(activeOrderModel?.status??0) <=1?
             (activeOrderModel?.productsOL??[]) 
             : (activeOrderModel?.pickedProductsOL??[])} />
         
        <PaymentMethod method={paymentMethod} lastFourDigits={lastFourDigits} />
      </div>)}
    </div>
  );
};

export default OrderTrackingPage;
