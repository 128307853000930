import { Timestamp } from "firebase/firestore";

export const REACT_APP_GOOGLE_MAPS_KEY = "AIzaSyCkDy6xPJ7X8awhoO31_dcjde54BKHixwc";

export const ReadAddress = (addressO) => {
    let theCurrentStoreAddress ;
    theCurrentStoreAddress =  (addressO?.areaT??{})['en'] + ", "
    theCurrentStoreAddress = theCurrentStoreAddress + (addressO?.district)
    return theCurrentStoreAddress;
};
const WEBLINK = 'https://web.naslst.com/b/';
export const getStoreWebLink = ( storeId) => {
    return WEBLINK + storeId;
  }

  export const getPaymentFromId = ( paymentId) => {
    switch(paymentId) {
      case 2:
        return "visa-mastercard";
        break;
      case 3:
        return "fawry";
      case 4:
        return "Meeza";
      case 12:
        return "Aman";
      case 14:
        return "Massary";
        break;
      default:
        return paymentId?.toString();
    }

  }  


  export const readOrderStatus = (status) => {
    let result = "New";
    switch (status??0) {
      case 1:
        result = 'New';
        break;
      case 2:
        result = 'Accepted';
        break;
      case 3:
        result = 'Picking Items';
        break;
      case 4:
        result = 'Ready';
        break;
      case 5:
        result = 'Waiting Delivery';
        break;
      case 6:
        result = 'On Delivery';
        break;
      case 7:
        result = 'Delivered';
        break;
      case 8:
        result = 'Canceled by Customer';
        break;
      case 9:
        result = 'Rejected by Store';
        break;
  
      case 20:
        result = 'Returned';
        break;
  
      default:
        result = 'Close';
    }
    return result;
  }

  export function readTimestamp(timestamp) {
    
    const timestampNow = timestamp??Timestamp.now()
    // Create a new Date object using the timestamp
    const date = timestampNow.toDate();
    // var date = new Date(timestamp).toDateString()
  
    // Get the date components
    const day = date?.getDate()??"";
    const month = (date?.getMonth()??0) + 1; // Months are zero-based, so add 1
    const year = date?.getFullYear()??"";
  
    // Get the time components
    const hours = date?.getHours()??"";
    const minutes = date?.getMinutes()??"";
    const seconds = date?.getSeconds()??"";
  
    // Format the date and time into a readable string
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  
    return `${formattedDate} ${formattedTime}`;
  }

  export function readTimestampN(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Returns a localized date and time string
  }