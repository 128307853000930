import './App.css';
import StoreScreen from './screen/StoreScreen';
import LoginScreen from './screen/login';
import { AppProvider } from './provider/AppContext'; // Import the AppProvider
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import StoreSearch from './screen/storeSearch';




function App() {
  console.log('here App011')
  // const storeScreen = React.useMemo( () => 
    // <StoreScreen key={"storeScreenHome1"} theId= {"3aish.w.salt"}  />, [] );

  
  const theme = createTheme({
    palette: {
      primary: {
        // main: '#f7c441',  // Your custom primary color (hex code)
        main: '#04032B',  // Your custom primary color (hex code)
      },
      secondary: {
        // main: '#17150c',  // Optional: customize the secondary color
        main: '#04032B',  // Optional: customize the secondary color
      },
    },
  });
  // <AppProvider></AppProvider>
  {/* <UserProfile /> */}
      {/* <StoreScreen key={"storeScreenHome1"} theId= {"dietdelighteg"}  /> */}
      {/* <PhoneAuth   /> */}
      
  return (
    <ThemeProvider theme={theme}>
          <AppProvider>
          {/* <APIProvider apiKey={Googl_Key} onLoad={() => console.log('Maps API has loaded.')}> */}
          <Router>
            <Routes>
              {/* <Route exact path="/" component={PhoneAuth} /> */}
              {/* <Route exact path="/b/:username" component={PhoneAuth} /> */}
              {/* <Route component={PhoneAuth} /> */}
              
                <Route path="/" element={<StoreSearch key={'&&times'} />} />
                <Route path="/b/" element={<StoreSearch key={'&&times'} />} />
                <Route path="/login" element={<LoginScreen key={'&&times'} />} />
                <Route path="/b/:username"  element={<StoreScreen key={'&&times'} />} />
                <Route path="*" element={<LoginScreen key={'&&times'} />} />
                
            </Routes>
        </Router>

        {/* </APIProvider> */}
        </AppProvider>
      </ThemeProvider>
  );
}

export default App;
