import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../provider/AppContext';
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Divider,
  Avatar,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { functions,httpsCallable,Timestamp } from '../firebase';
// import { httpsCallable } from "firebase/functions";
import {addDoc, collection, doc,getDoc, } from "firebase/firestore";
import { firestore } from '../firebase.js';
import { v1 as uuidv1 } from 'uuid';
import { getStoreWebLink } from '../constants/constants.js';
import { generateFawaterkSessionForOnlineOrder } from '../constants/checkoutPayment.js';
import PaymentOverlay from '../widgets/fawaterak.js';
import OverlayWindow from '../widgets/overlayWindow.js';
import PaymentUI from './paymentUI.js';


const CheckoutScreen = ({storeProductO, onClose , closeCart , openChat }) => {
    const subTotal = storeProductO.productsOL.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const [loading, setLoading] = useState(true);
    const [blackVisible, changeBlackVisible] = useState(false);
    const [error, setError] = useState(null);
    const [screen , setScreen] = useState(0);
    const [_qrResponse , setQRResponse] = useState("");
    const [gatewayResponse, setGateWayResponse] = useState('');
    const [ _payResponse, setPayResponse] = useState(null);

    const [total, setTotal] = useState(subTotal??0);
    const [orderModel, setOrderModel] = useState({});
    const [result, setResult] = useState();
    const [gateWayResultL, setGateWayList] = useState([]);
    
    const [promoCode, setPromo] = useState('');
    const [walletCtr, setWalletCtr] = useState('');
    const [orderNote, setOrderNote] = useState('');
    const { state, handleActiveOrderId , clearStoreProductO } = useContext(AppContext);  // Access global state ...

    const handlePromoInputChange = (e) => {
        const value = e.target.value;
        setPromo(value);
      };
   
      const handleWalletCtr = (e) => {
        const value = e.target.value;
        setWalletCtr(value);
      };
      const handleNoteInputChange = (e) => {
        const value = e.target.value;
        setOrderNote(value);
      };
    
    const callStoreOrderCheckout = async (values) => {
        
        const callable = httpsCallable(functions, 'storeOrdercheckOut');
        try {
          const resp = await callable(values); // Call the function with the values
          if (resp?.data?.toString().length > 0) {
            console.log("Response data:", resp.data);
            return resp.data;
          }
        } catch (e) {
          console.error("Error calling storeOrderCheckOut:", e);
        }
        
        return null;
      };
      
      
      
      
      
    const handleStoreOrderFunc = async () => {
      setLoading(true)
      const position = (state.userModel?.position??{});
      const positionToJsonString = {
        'geohash': position?.geohash,
        'geopoint' :  {
          "latitude": position?.geopoint?.latitude,
          "longitude": position?.geopoint?.longitude,
        }
      }
       // Usage example
      const values = {
        "userType": 'customers',
        "userId": state.userModel?.id??"",
        "productsOL": storeProductO?.productsOL??[],
        "orderType": storeProductO?.orderType??"",
        "position": positionToJsonString,
        "storeId": storeProductO?.storeId??"",
        "specsN": state.userModel?.specsN??"",
        "promoCode": promoCode??"",
        "orderNote":orderNote,
      };

       await callStoreOrderCheckout(values).then((result) => {
            if (result) {
              console.log("Checkout result:", result);
              const feesOL = result?.feesOptionsOL??[]
              const deliveryPrice = result?.deliveryPrice??0
              const allowed = result?.allowed??false;
              const theLog = result?.log??"";
              setResult(result)
              const deliveryCost = result?.deliveryCost??0
              const totalFeesOL = feesOL.reduce((acc, item) => acc + item.cost , 0);
              setTotal(subTotal + deliveryPrice + totalFeesOL);
              setOrderModel({
                "userType": 'customers',
                "userId": state.userModel?.id??"",
                "productsOL": storeProductO?.productsOL??[],
                "orderType": storeProductO?.orderType??"",
                "position": state.userModel?.position??{},
                "storeId": storeProductO?.storeId??"",
                "specsN": state.userModel?.specsN??"",
                "promoCode": promoCode??"",

                "orderFeesOL":feesOL,
                "deliveryPrice":deliveryPrice,
                "deliveryCost":deliveryCost
              })
              setGateWayList(result.gateWayResultL)
            } else {
              setResult(null)
            }
          });
          setLoading(false)
    }

    useEffect(() => {
      if (screen == 0) {
        handleStoreOrderFunc();
      }
        
      }, []);

     const scheduleTime = Timestamp.now()
    
     const confirmCurrentOrder = async ()=>  {
      if(screen == 0 && loading == false) {
        setLoading(true);
        const newSpecs = [];
    //   const newSpecsM = storeModel?.specsM??{};

      newSpecs.push(state?.userModel?.addressO?.specsN ?? "egypt");
      newSpecs.push((state?.userModel?.addressO?.specsN ?? "egypt") + "-" +(storeProductO.orderType?.toLowerCase()??""));
      newSpecs.push('customers' + (state?.userModel?.id??""));
      newSpecs.push('customers' + (state?.userModel?.id??"") + "-" +(storeProductO.orderType?.toLowerCase()??""));
    //   newSpecs.push(storeModel?.specsL??[]);
    //   storeModel?.specsL??[].forEach((element) => {
    //   newSpecs.add(element + "-" +(storeProdO.orderType?.toLowerCase()??""));
    //   });
      newSpecs.push('stores' + (storeProductO?.storeId??""));
      newSpecs.push('stores' + (storeProductO?.storeId??"") + "-" + (storeProductO.orderType?.toLowerCase()??""));

      const uuidValue = "kjhl"
      const currentTimestamp = Timestamp.now();
      
      const newOrder = {
        uuid: uuidValue,
        cstId: state.userModel?.id??"",
        cstName: state.userModel?.name??"",
        cstLang: state.userModel?.lang??"",
        storeId: storeProductO?.storeId??"",
        storeAssignedId: storeProductO?.storeId??"",
        storeNameT: storeModel?.nameT??{},
        productsOL: orderModel?.productsOL??[],
        pickedProductsOL:[],
        premiumOL: [],
        ordersCount: 1,
        orderType: storeProductO?.orderType??"",
        table: storeProductO?.table??null,
        totalOrderCost: total,
        deliveryCost: orderModel.deliveryCost,
        deliveryPrice: orderModel.deliveryPrice,
        pickedProductsOM: [],
        storeAddressO: storeModel?.addressO,
        cstAddressO: state.userModel?.addressO,
        specsL: newSpecs,
        specsM: {},
        specsN: storeModel?.specsN??"",
        lastMsgId: '',
        active: true,
        activeInStore:true,
        inStore: true,
        inDelivery: false,
        status: 1,
        vehicle: 0,
        submitTime: currentTimestamp,
        lastUpdate: currentTimestamp,
        statusUpdates: [{
            status: 1,
            lastUpdate: currentTimestamp,
            scheduledOn: scheduleTime
          }],
        scheduledOn: scheduleTime,
        position: storeModel?.position,
        driverId: '',
        returned: false,
        deliverByStore: false,
        edited: false,
        driversAppliedOL: [],
        storeSeen: false,
        orderNote: orderNote,
        acceptReplacement: false,
        forceNewOrder: true,
        packageOL: [],
        orderFeesOL: orderModel?.orderFeesOL??[],
        internal: false,
        onCreateCalled: false,
        createTime: currentTimestamp,
        promoCode: promoCode
      }
      
    
      if ((newOrder?.cstId??"")!="" &&(state.user?.uid??"")!="" && (storeModel?.id??"") !="" && (state.userModel?.id??"") != "") {
        // sending order to the store
        if (state.user) {
          
          if ( selectedPayment.name_en == 'Cash') {
            console.log("Order Model: ", newOrder);
            const collectionRef = collection(firestore, 'orders');
            const orderRef = await addDoc(collectionRef,  newOrder);
            
            clearStoreProductO(storeModel);
            
            closeCart(false);
            onClose(false);
            openChat(true);
            handleActiveOrderId(orderRef.id);
            
          } else {
            let allOrdersTotal = 0;
            const _customPayLoadL = [];
            let _payResponse = "";
            allOrdersTotal = allOrdersTotal + (total??0);
            const uiiid = uuidv1();
            const payment = {
              uuid: uiiid,
              name: selectedPayment?.name_en??"",
              amount: total,
              paymentID: selectedPayment?.gatewayId??"",
              toRef: "storeBankWallet/" + (newOrder?.storeAssignedId??""),
            };
            const thePayload = {
              destination: 'orders',
              userId: state.userModel?.id,
              userCollection: 'customers',
              payloadM: newOrder,
              paymentO: payment,
            };
            _customPayLoadL.push(thePayload);
          
          const defaultCart =  {
            name: state.userModel?.id,
            price: (total/100).toString().trim(),
            quantity: '1',
          };
          let walletPhone = "01000000000";
          if (selectedPayment?.paymentId == 4) {
            walletPhone = walletCtr;
          }
          const defalultCustomer = {
            first_name: 'admin',
            last_name: 'naslst',
            email: 'admin@naslst.com',
            phone: walletPhone,
            address: 'El merghany office',
          };
          const thePayResponse = await generateFawaterkSessionForOnlineOrder(
              selectedPayment?.paymentId, total,
              state.userModel, 'customers',
              'newOnlineOrder' , 'cstWallet/'+(state.userModel?.id??""),
              [defaultCart],
              _customPayLoadL,
              defalultCustomer,
              selectedPayment,
              getStoreWebLink(storeModel?.username??storeModel?.id)
          );
          setPayResponse(thePayResponse);
          const _response = (thePayResponse?.paymentLink??"") !="" ? thePayResponse?.paymentLink : thePayResponse.paymentReference;

          if (_payResponse?.paymentMethodId == 4) {
            console.log('payment via mobile wallet');
            const theQrResponse = (_payResponse?.responseData??{})['data']['payment_data']['meezaQrCode'];
            setQRResponse(theQrResponse);
            console.log('payment via mobile wallet QR is ' + _qrResponse);
          }
          setGateWayResponse(_response);
          
          console.log('response is  ' + _response);
          if ((_response??"").toString().length > 0) {
            console.log("Yea baby yea " , gatewayResponse);
            console.log(gatewayResponse);
            if ((_response??"").startsWith("http")) {
              console.log('this is payment Link')
              setScreen(1);
              changeBlackVisible(false);
              // This auto directed to webviewer.dart if platform is web
              //print("FawaterakPayment Going up from here");
            } else {
              // for ( newOrder in allOrdersConfirmedL) {
              //   if (userProv.cashPayment == true) {
              //     await needLstProv.resetCart(newOrder, newOrder?.storeAssignedId??"");
              //   }
              // }
              setScreen(2);
            }
            setLoading(false);
            
          } else {
            //print("no url ya sadeeky");
            setScreen(3);
            setLoading(false);
            
            changeBlackVisible(false);
           }
          }
          }
      } else {
        console.log("Order Model cst: ", orderModel.cstId);
      }
      setLoading(false);
      }
    }

    const [storeModel, setStoreModel] = useState(null);


    
  
    const cashGateWay = {name_en:"Cash", name_ar:"Cash" , logo:"",paymentId:""}
    const [selectedPayment, setSelectedPayment] = useState(cashGateWay);
    const changeSelectedPayment = (paymentSelected) => {
      setSelectedPayment(paymentSelected);
    }

    useEffect(() => {
        console.log('here 00')
        const fetchStores = async () =>  {
          try {
          const theId = (storeProductO?.storeId)
          console.log("cart query stores:" +  theId);
          const q = doc(firestore, 'stores', theId);
      
          const storeDocument = await  getDoc(q);
      
          const storeData =    storeDocument.data()
            
            console.log("Fetched cart stores:", (storeData.nameT??{}['en']));
            const stores  = storeData;
            
            setStoreModel(stores);
            
          } catch (err) {
            setError(err.message);
            
          }
        };
      
        console.log('here 0')
          
        fetchStores();
        
      }, [loading, ]);

    return (
    <Box
      sx={{
        // width: '100%',
        // maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div>

      {/* <button onClick={handlePaymentResponse} disabled={loading}>
        Process Payment
      </button> */}

      
      
      {screen === 1 && (
        <div>
          {/* Here you can embed your web viewer */}
          <PaymentOverlay 
            isModalOpen={screen === 1}
            paymentUrl={gatewayResponse}
            closeModal={() => { setScreen(0)}}
          //  paymentUrl={"https://staging.fawaterk.com/lk/24921"}
           />
        </div>
      )}
      {screen === 2 && 
      <OverlayWindow
      isOpen={screen == 2}
      closeModal={() => {setScreen(0);}}
      key={'checkOutDrawerModal'}
      children={(
      <PaymentUI
       theResponse={gatewayResponse} 
      gatewayResponse={gatewayResponse}
      invoiceDocument={_payResponse}
      onCompleted={() => {setScreen(0)}}
      ></PaymentUI>)}
    
    >
      
    </OverlayWindow>
      }
      {screen === 3 && <div>Failed to complete payment.</div>}
    </div>
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <IconButton onClick={onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ marginLeft: '10px' }}>
          Check out
        </Typography>
      </Box>

      {/* Delivery Address */}
      <Box
        sx={{
          padding: '15px',
          borderRadius: '8px',
          backgroundColor: '#f7f7f7',
          marginBottom: '10px',
        }}
      >
        
        <Box style={{width: '100%',}} 
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
        <Typography variant="body1" fontWeight="bold">
          {(state.userModel?.addressO?.cityT??{})['en']}{", "}
          {(state.userModel?.addressO?.areaT??{})['en']}
        </Typography>
        <Button
          sx={{
            backgroundColor: 'yellow',
            color: 'black',
            fontSize: '12px',
            borderRadius: '5px',
            padding: '2px 8px',
            marginTop: '8px',
          }}
        >
          Primary
        </Button>
        </Box >

        <Typography variant="body2">
        {(state.userModel?.addressO?.district??"")}{", "}
        {(state.userModel?.addressO?.st)}{", "}
        {(state.userModel?.addressO?.buildingNum)}{", "}
        {(state.userModel?.addressO?.floorNum)}{", "}
        {(state.userModel?.addressO?.apartmentNum)}{", "}
        {(state.userModel?.addressO?.landMark)}{", "}
        
        </Typography>
        <Typography variant="body2">Mobile : {state.userModel?.addressO?.phone??state.userModel?.phone??""}</Typography>
        
        
      </Box>

      {/* Order Summary */}
      <Box
        sx={{
          padding: '15px',
          borderRadius: '8px',
          backgroundColor: '#f7f7f7',
          marginBottom: '10px',
        }}
      >
        <Box
        sx={{
          
          marginBottom: '6px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={storeModel?.logo??"https://via.placeholder.com/50"}
          sx={{ width: 56, height: 56, marginRight: '10px' }}
        />
        <Box 
        sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h6">{(storeModel?.nameT??{})['en']}</Typography>
          <Typography variant="body2" color="primary">
            {storeProductO?.orderType??"Delivery"}
          </Typography>
        </Box>
        
      </Box>
      <Box 
        sx={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h7">Subtotal </Typography>
          <Typography variant="h7">{subTotal/100} {" "}{storeModel?.currency??" LE"}</Typography>
        </Box>

        {storeProductO.orderType == "Deliver" &&(<Box 
        sx={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h7">Delivery </Typography>
          <Typography variant="h7">{(orderModel?.deliveryPrice??0)/100} {" "}{storeModel?.currency??" LE"}</Typography>
        </Box>)}
      <Box>  
        {(orderModel?.orderFeesOL??[]).map((feeO, indexS) => (
              
              <Box key={indexS}
                sx={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="h7"
                  style={{color:feeO.cost<0 ? "red":"black"}}
                  >{feeO?.name??""}</Typography>
                  <Typography variant="h7">{(feeO?.cost??0)/100} {" "}{storeModel?.currency??" LE"}</Typography>
                </Box>
            ))}
        
        <Box 
        sx={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h7">Total</Typography>
          <Typography variant="h7"> ${total/100} {" "}{storeModel?.currency??" LE"}</Typography>
          
        
        </Box>
        {loading && < div 
        style={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >Loading...</div>}
        </Box>
      </Box>
      
      

      {/* Promo Code */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <TextField
            onChange={handlePromoInputChange}
          fullWidth
          label="Promo code"
          variant="outlined"
          sx={{marginRight: '10px' }}
        />
        <Button 
        onClick={handleStoreOrderFunc}
        variant="contained" color="primary">
          Apply
        </Button>
      </Box>

      {/* Note Input */}
      <TextField
        onChange={handleNoteInputChange}
        label="Note"
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        sx={{ marginBottom: '20px' }}
      />

      {/* Accept Replacement Checkbox */}
      

      {/* Notice */}
      
      

      {/* Payment Method */}
      <Typography variant="h6" gutterBottom>
        Payment Method
      </Typography>
      <Button
      onClick={() => changeSelectedPayment(cashGateWay)}
        variant={selectedPayment.name_en == cashGateWay.name_en?"outlined":""}
        fullWidth
        sx={{
            padding: '5px',
            borderColor: '#17150c',
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '10px',
            borderRadius: '25px',
          }}
      >
        <Avatar
          src="https://via.placeholder.com/40"
          alt="Cash Icon"
          sx={{ marginRight: '10px' }}
        />
        Cash
      </Button>

      {/* Payment Option - Visa/Mastercard */}
      {gateWayResultL.map((gateWat, indexZ) => (
        <div key={indexZ}>
        <Button key={indexZ}
        onClick={() => changeSelectedPayment(gateWat)}
        variant= {selectedPayment.name_en == gateWat.name_en? "outlined": ""}
        fullWidth
        sx= {{
          padding: '5px',
          borderColor: '#17150c',
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '10px',
          borderRadius: '25px',
        }}
      >
        <img 
              width={50} 
              src={gateWat?.logo??""}
              alt="Logo" 
              style={{ 
                marginLeft: '10px', 
                marginRight: '10px', 
                borderRadius: '10%'  // Circular image
              }} 
            />
        
        {gateWat.name_en}
        {/* paymentId */}
      </Button>
      {selectedPayment.paymentId == 4 && selectedPayment.name_en == gateWat.name_en && (
        <TextField
            onChange={handleWalletCtr}
            fullWidth
            label="Wallet phone"
            variant="outlined"
            sx={{marginRight: '10px' }}
          /> )}
        </div>
        
      ))}
      {result?.log}
      {/* Confirm Button */}
      <Button
        variant="contained"
        fullWidth
        onClick={confirmCurrentOrder}
        sx={{
          backgroundColor: 
          loading !== true && screen == 0 ? "#ffd700":"#f7f7f7",
          // backgroundColor: '#f7f7f7',
          color: 'black',
          padding: '10px',
          fontSize: '16px',
          borderRadius: '8px',
        }}
      >
        {loading?
        "Loading ...": screen == 0 ?"Confirm" : "Waiting payment"}
      </Button>

    </Box>
  );
};

export default CheckoutScreen;
