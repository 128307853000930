import React, { useState, useEffect, useContext, useRef } from 'react';
import { firestore, storage, functions,httpsCallable } from '../firebase'; // Adjust the import to your Firebase config
import {getDoc, doc, collection, query, where, orderBy, limit, startAfter, onSnapshot, addDoc, serverTimestamp, getDocs } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { AppContext } from '../provider/AppContext'; // Import the context
import '../chat.css';
import { Box,  useMediaQuery, Button, AppBar, Toolbar, TextField, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';


function ChatScreen({ connectionPath, storeModel, isVisible, closeChat }) {
   
  // if(connectionPath == undefined){
  //   connectionPath = 'HGkA4uGl4RLyNZhIXMmv' 
  //  }
   
  const [activeConnectionPath, setActiveConnectionId] = useState(connectionPath??"")
   const { state,userModel  } = useContext(AppContext); // Access the cart state and the function to update the cart

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [newMessageAdded, setNewMessageAdded] = useState(false);


  const [lastVisible, setLastVisible] = useState(null);
  const [isSending, setIsSending] = useState(false);
  // const messageEndRef = useRef(null);

  const [otherUserName, setOtherUserName] = useState('');
  const [otherPic, setOtherUserPic] = useState('');
  const [otherName, setOtherName] = useState('');
  const currentUser = state.user?.uid??""; // Replace this with the actual current user ID
  const chatContainerRef = useRef(null);
  const fullFrameRef = useRef(null);
  const currentUserPath = "customers/"+ (state.user?.uid??""); // Replace with actual user ID from authentication
  
  const getConnectionPathFromCloud = async (user1Ref, user2Ref) => {
    const callable = httpsCallable(functions, 'startActiveConnection');
        try {
          const resp = await callable({
            'userRef1': user1Ref ,
            'userRef2': user2Ref ,
            'createNew': true,
          }); // Call the function with the values
          if (resp?.data?.toString().length > 0) {
            console.log("Response data:", resp.data);
            return resp.data;
          }
        } catch (e) {
          console.error("Error calling storeOrderCheckOut:", e);
        }
        
        return null;
  }

  useEffect(() => {
    const fetchMessages = async () => {
        setLoading(true);
        console.log('start fetchMessages')
        let connectionResult = activeConnectionPath??"";
        if (state.user && (activeConnectionPath??"") == "" && (storeModel?.id??"")!="") {
          console.log('getting connection');
          connectionResult = await getConnectionPathFromCloud("customers/"+(state.user?.uid??""), "stores/"+ (storeModel?.id??"") )
          setActiveConnectionId(connectionResult);
          setLoading(true);
        }
        // fetching messages 
        try {
        if (state.user && (activeConnectionPath??"") != "" && (storeModel?.id??"")!="") {
          console.log('found connection ' + activeConnectionPath);
          const connectionDoc = await getDoc(doc(firestore, activeConnectionPath));
        if (connectionDoc.exists()) {
            const data = connectionDoc.data();
            if(currentUser != ""){
              const otherUser = currentUser === data.userId1 ? data.userId2 : data.userId1;
              setOtherUserName(currentUser === data.userId1 ? data.username2 : data.username1);
              setOtherUserPic(currentUser === data.userId1 ? data.pic2 : data.pic1);
              setOtherName(currentUser === data.userId1 ? data.name2 : data.name1);
            }
            

            const messagesQuery = query(
                collection(firestore, `${activeConnectionPath}/chat`),
                where('active', '==', true), // Adjust this to suit your needs
                orderBy('timestamp', 'desc'),
                limit(20)
            );

            onSnapshot(messagesQuery, (snapshot) => {
                const newMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                if (snapshot.docs.length > 0) {
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                    
                }

                // Reverse the messages and set them in state
                setMessages(newMessages.reverse());
                if (newMessage.length > 0) {
                  setNewMessageAdded(true);   
                  
                }
                
            });
        }
        setLoading(false);
        }
      } catch {
        console.log('error getting chat')
      }
    };

    fetchMessages();
}, [activeConnectionPath, connectionPath, state, storeModel, userModel]);



useEffect(() => {
  // Scroll to the bottom if a new message is added
  
  if (chatContainerRef.current && newMessageAdded) {
    setTimeout( async () => {
      chatContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setNewMessageAdded(false); // Reset the flag after scrolling               
    }, 500);
     
  }
}, [messages, newMessageAdded]);
// Scroll to the bottom when a new message is added



  // Handle scrolling to load more messages
  const fetchMoreMessages = async () => {
    if (!activeConnectionPath || loading || lastVisible == null) return;

      
      try {
        setLoading(true);
        console.log('fetchMoreMessages')
        // const currentScrollHeight = chatContainerRef.current.scrollHeight;

        const messagesQuery = query(
          collection(firestore, `${activeConnectionPath}/chat`),
          where('active', '==', true), // Adjust this to suit your needs
          orderBy('timestamp', 'desc'),
          startAfter(lastVisible),
          limit(20)
        );
  
        const messageSnapshot = await getDocs(messagesQuery);
        const newMessages = messageSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        if (newMessages.length > 0) {
          setLastVisible(messageSnapshot.docs[messageSnapshot.docs.length - 1]);
          setMessages((prevMessages) => [ ...newMessages.reverse(), ...prevMessages]);
                    
        } else {
          setLastVisible(null)
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching more messages: ", error);
      }
      
    
  };
  


  

const handleScroll = () => {
  console.log('handleScroll, fetchMoreMessages');
    if (chatContainerRef.current && 
      chatContainerRef.current.scrollTop < 100
      && !loading) {
        
      fetchMoreMessages();
    }
};

  

  // Send a new message
const sendMessage = async (type = '', attachment = null) => {
    if (newMessage.trim() === '' && !attachment) return;

    setIsSending(true);
    try {
        const messageData = {
            userIdRef: currentUserPath,
            body: newMessage,
            msgType: type,
            timestamp: serverTimestamp(),
            seenL: [currentUserPath],
            msgTypeId: attachment,
            active: true,
        };
        
        await addDoc(collection(firestore, `${activeConnectionPath}/chat`), messageData);
        setNewMessage('');

        // Scroll to the bottom when a new message is sent
        setNewMessageAdded(true); 

        if (inputRef.current) {
            inputRef.current.focus();
        }
    } catch (error) {
        console.error("Error sending message: ", error);
    }
    setIsSending(false);
};


  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsSending(true);
    try {
      const storageRef = ref(storage, `messages/${activeConnectionPath}/${file.name}`);
      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);

      await sendMessage('Image',  fileUrl);
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
    setIsSending(false);
  };

  // Render messages
  const renderMessage = (message) => {
    const isMine = message.userIdRef === currentUserPath;
    const messageClass = `message ${isMine ? 'right' : 'left'}`;
    const seen = (message?.seenL??[]).includes(currentUserPath);

    switch (message.msgType??"") {
        case '':
          return (
            <div key={message.id} className={`message ${isMine ? 'right' : 'left'}`}>
              <p>{message.body}</p>
            </div>
          );
        case 'Image':
          return (
            <div 
            key={message.id} className={`message ${isMine ?'right' : 'left'}`}>
              <img src={message.msgTypeId} alt="Message attachment" />
              <div className="status">{seen ? '✓✓' : '✓'}</div>
            </div>
          );
        case 'New order':
          const orderId = message.msgTypeId;
          // Load and render the order document here
          return (
            <div key={message.id} className={`message ${isMine ? 'right' : 'left'}`}>
              <p>Order : {orderId} {/* Render order details here */}</p>
              <div className="status">{seen ? '✓✓' : '✓'}</div>
            </div>
          );
        case 'Rating':
          // Load and render the rating document here
          return (
            <div key={message.id} className={`message ${isMine ? 'right' : 'left'}`}>
              <p>Rating: {/* Render rating details here */}</p>
              <div className="status">{seen ? '✓✓' : '✓'}</div>
            </div>
          );
        default:
          return null;
      }

    // return (
    //   <div key={message.id} className={messageClass}>
    //     {(message.msgType??"") === '' && <p>{message.body}</p>}
    //     {message.msgType === 'Image' && <img src={message.customAttachment?.imageUrl} alt="attachment" />}
    //     <div className="status">{seen ? '✓✓' : '✓'}</div>
    //   </div>
    // );
  };
  const fileInputRef = useRef(null); 
  const inputRef = useRef(null);

  // Handle clicks outside of the chat window to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (fullFrameRef.current && !fullFrameRef.current.contains(event.target)) {
        closeChat();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, closeChat]);
  
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener on component unmount
    return () => {
        if (chatContainerRef.current) {
          chatContainerRef.current.removeEventListener('scroll', handleScroll);
        }
    };
}, [chatContainerRef]);


  const isSmallScreen = useMediaQuery('(max-width: 800px)'); // Adjust screen size for responsiveness
  if (!isVisible) return null;

  return (<Box
    ref={fullFrameRef}
    sx={{
      position: 'fixed',
      bottom: 0,
      right: isSmallScreen ?20 : 0,
      width: '100%',
      maxWidth:'400px',
      backgroundColor: 'white',
      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
      borderRadius: '10px 10px 0 0',
      zIndex: 1000,
      animation: 'slide-up 0.3s ease-out',
      height: '71vh', // Adjust this height as per your requirement to fit the chat content
      overflow: 'hidden', // This ensures that no content overflows out of the box

      '@keyframes slide-up': {
        from: {
          transform: 'translateY(100%)',
        },
        to: {
          transform: 'translateY(0)',
        },
      },
    }}
  >
  
    {/* Chat Header */}
    
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px',
            backgroundColor: 'primary',
            borderRadius: '10px 10px 0 0',
        }}
    >
        <AppBar position="static" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }}>
            <Toolbar>
                <div className="chat-header">
                    <img src={otherPic} alt="User" className="profile-pic" />
                    <div className="user-info">
                        <h2>{otherName} {messages.length}</h2>
                        <p>@{otherUserName}</p>
                    </div>
                </div>
                <IconButton onClick={closeChat} sx={{ color: 'white' }}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    </Box>

    {/* Scrollable Chat Messages */}
    <Box
    sx={{
      height: 'calc(80vh - 64px - 140px)', // Adjust height based on the overall chat container
      overflow: 'hidden', // Prevent the outer container from scrolling
    }}
  className="chat-container" // Attach chat-container class
   
   
>
  {/* Message List Container */}
  <div className="messages-container"
  
  >
    <div className="messages-list"
    ref={chatContainerRef} // Attach the ref here
    onScroll={handleScroll}
    >
      {messages.map(renderMessage)}
    </div>
  </div>
  </Box>

    {/* Message Input and Send Button */}
    

    <div className="input-container">
        <input
          type="text"
          value={newMessage}
          autoFocus={true}
          ref={inputRef} // Attach the ref to the input element
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              sendMessage(); // Send message on Enter key press
            }
          }} 
          placeholder="Type a message..."
          disabled={isSending}
        />
        
        {/* <input type="file" 
        onChange={handleFileUpload}
        
        /> */}
        
        {/* Attachment Icon Button */}
        {(<IconButton
            disabled={isSending}
            component="span" onClick={() => {
              if (!isSending) {
                fileInputRef.current.click();
              }
             }
            }>
                <AttachFileIcon />
            </IconButton>)}

            {/* Hidden File Input */}
            <input
                type="file"
                disabled={isSending}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
            /> 
            <button onClick={() => sendMessage()} 
        
        disabled={isSending}>Send</button>
      </div>
  </Box>);
}

export default ChatScreen;
