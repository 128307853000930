import React, {  useState, useEffect, useContext } from 'react';
import SearchLocationInput from "../components/GooglePlcasesApi";
import MapComponent from '../components/Map';
import { AppContext } from '../provider/AppContext'; // Import the context
import { ReadAddress } from '../constants/constants';
import {  ListItem, ListItemText,
    
} from '@mui/material';

const AddNewLocation = ({closeModal , }) => {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [address, setAddress] = useState('');
    
    const {state, recentAddressL, updateUserAddress} = useContext(AppContext); // Access the cart state and the function to update the cart
    const [locationError, setLocationError] = useState('');

    const [selectedLocation, setSelectedLocation] = useState({
        searchKey:"",
        lat: 30.0444,
        lng: 31.2357,
      });


      // Handle geolocation (detect current location)
  const handleShareLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude ,  } = position.coords;
          setSelectedLocation({lat: latitude, lng: longitude});
          updateUserAddress({lat: latitude, lng:longitude});
          // closeModal();
        },
        (error) => {
          setLocationError('Unable to retrieve location.');
        }
      );
    } else {
      setLocationError('Geolocation is not supported by this browser.');
    }
  };

  // Handle Login Button Click
  const handleLoginClick = () => {
    // Call the function to navigate to login
    // navigateToLogin();
  };
   // Handle clicking outside the modal content
   const handleOverlayClick = () => {
    closeModal();
  };

    // Prevent modal close on clicking inside the modal content
    const handleModalContentClick = (event) => {
        event.stopPropagation();
      };

    const hasNewLocation = () => (state.currentGeoPoint?.latitude != state.userModel?.addressO?.position?.geopoint?.latitude &&
      state.currentGeoPoint?.longitude != state.userModel?.addressO?.position?.geopoint?.longitude);

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content" onClick={handleModalContentClick}>
                <button 
                style={{
                    position:'static',
                        top:0,
                        right:0,

                }}
                onClick={closeModal} className="close-modal-btn">
            X
            </button>
            <h2 >Let us know where you are !</h2>
            <p>Help us to confirm availability & delivery fees.</p>

            <div  style={{ height: "80%", width: "100%" }} >

                <SearchLocationInput setSelectedLocation={setSelectedLocation} />


                {/* Share Location Button */}
                <button className="share-location-button" onClick={handleShareLocation}>
                <i className="location-icon">📍</i>
                Current location
                </button>
                {selectedLocation.searchKey != "" && (
                  <div>
                <MapComponent 
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                />
            <button className="confirm-button"
                onClick={() => {
                  updateUserAddress({lat: selectedLocation.lat, lng:selectedLocation.lng});
                  closeModal();
              }}
              >
                Confirm this location
              </button>
                  </div>
                  )
                
                }
                
                
                { hasNewLocation() && (<div>
                    <div className="location-input" >
                    {/* <label>Type in your suburb or postcode</label> */}
                    <i className="location-icon">📍</i>
                    
                    <ListItem 
                        button key={state.currentGeoPoint?.latitude} 
                        style={{
                          cursor: 'pointer',
                            border:  '1px solid #ffc107',
                            // background:'gray'
                        }} >
                        <ListItemText 
                        onClick={() => {
                            closeModal();
                        }}
                        primary={state.currentAddress} />
                    </ListItem>
                    </div>
                </div>)}
                {(state.user?.uid??"")==""? (    
                    <button className="login-button" onClick={handleLoginClick}>
                    Log in for saved addresses
                    </button>):null}
                {recentAddressL.map((address, indexS) => 
                ( <div key={indexS}>
                    <div className="location-input" >
                    {/* <label>Type in your suburb or postcode</label> */}
                    <i className="location-icon">📍</i>
                    
                    <ListItem 
                        button={'true'} key={address?.id} 
                        style={{
                          cursor: 'pointer',
                            border: !hasNewLocation() && 
                            address?.uuid == state.userModel?.addressO?.uuid ?
                            '1px solid #ffc107':
                            '1px solid rgba(0, 0, 0, 0.1)',
                            // background:'gray'
                        }} >
                        <ListItemText 
                        onClick={() => {
                            updateUserAddress({addressO:address});
                            closeModal();
                        }}
                        primary={ReadAddress(address)} />
                    </ListItem>

                
                    </div>
                    
                </div>)    
                )}

                

                {/* Error Handling */}
                {locationError && <p className="error-message">{locationError}</p>}

            </div>

                    {/* Close button */}
        

        </div>
      </div>
      );
}

export default AddNewLocation;