// src/CompleteProfile.js
import React, { useState,useContext } from 'react';
import { AppContext } from '../provider/AppContext'; // Import the context
import '../CompleteProfile.css';
import { storage, firestore } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Timestamp, doc, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FaSearch } from 'react-icons/fa';
import { Merge } from '@mui/icons-material';
import { getAuth, signOut } from 'firebase/auth';
import ScreenModal from '../widgets/popUp';
import { Box,  
} from '@mui/material';


const CompleteProfile = () => {
  const { state, } = useContext(AppContext); 
  const [profileImage, setProfileImage] = useState('https://via.placeholder.com/150');
  const [imageFile, setImageFile] = useState(null);
  const [fullName, setFullName] = useState('');
  const [emailCtr, setEmailCtr] = useState('');
  const [username, setUsername] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [uploading, setUploading] = useState(false);

  const functions = getFunctions();

  // Handle image selection
  const handleImageClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  // Generate a username from the full name
  const generateUsername = async (name) => {
    const suggestedUsername = name.toLowerCase().replace(/\s+/g, '');
    let newUsername = suggestedUsername;
    let isAvailable = false;

    // Check if the username is available
    setCheckingUsername(true);
    const checkIfUserNameExists = httpsCallable(functions, 'checkIfUserNameExists');
    try {
      let count = 0;
      while (!isAvailable && count < 10) { // Limit to 10 attempts
        const result = await checkIfUserNameExists({ username: newUsername });
        if (!result.data) {
          isAvailable = true;
          setUsernameAvailable(true);
        } else {
          // Add a random number to the username
          newUsername = `${suggestedUsername}${Math.floor(Math.random() * 1000)}`;
        }
        count++;
      }

      if (!isAvailable) {
        setUsernameAvailable(false);
      }

      setUsername(newUsername);
    } catch (error) {
      console.error('Error checking username:', error);
      setUsernameAvailable(false);
    } finally {
      setCheckingUsername(false);
    }
  };

  // Handle full name input change
  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);

    // Auto-generate username when the full name is entered
    if (value) {
      generateUsername(value);
    } else {
      setUsername('');
    }
  };

  

  // Handle username input change and check availability
  const handleUsernameChange = async (e) => {
    const value = e.target.value;
    setUsername(value);

    if (value) {
      setCheckingUsername(true);
      const checkIfUserNameExists = httpsCallable(functions, 'checkIfUserNameExists');
      try {
        const result = await checkIfUserNameExists({ username: value });
        console.log('user name result is', result.data);
        setUsernameAvailable(!result.data);
      } catch (error) {
        console.error('Error checking username:', error);
      } finally {
        setCheckingUsername(false);
      }
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!usernameAvailable) {
      alert('Username is already taken.');
      return;
    }

    const timeNow = Timestamp.now()
    setUploading(true);
    try {
      let downloadURL = profileImage;
      if (imageFile) {
        // Upload the image to Firebase Storage
        const storageRef = ref(storage, `customers/${imageFile.name}`);
        await uploadBytes(storageRef, imageFile);
        downloadURL = await getDownloadURL(storageRef);
      }

      // Check username availability again before submitting
      const checkIfUserNameExists = httpsCallable(functions, 'checkIfUserNameExists');
      const result = await checkIfUserNameExists({ username });
      if (result.data.exists) {
        alert('Username is already taken.');
        return;
      }

    const newSpecsNL = [];

    newSpecsNL.push(state.specsN??'' ??"unknownSpecsN");
    newSpecsNL.push(((state.cityT??{})['en'] ?? "unknown"));
    newSpecsNL.push((state.selectedCountryN ?? "unknown"));
    newSpecsNL.push(((state.selectedAreaT ?? {})['en'] ?? "unknown"));
    newSpecsNL.push("worldWide");

      // Update Firestore with user info (Replace 'userId' with the actual user ID)
      const userDocRef = doc(firestore, 'customers', state.user.uid); // Replace 'userId' with the actual user ID
      await setDoc(userDocRef, {
        id:state.user?.uid??'',
        name: fullName,
        email: emailCtr??"",
        country: state.selectedCountryN??'',
        city: (state.cityT??{})['en'],
        area: (state.selectedAreaT ?? {})['en'],
        specsN: state.specsN??'',
        specsL: newSpecsNL,
        specsM:{},
        pic: downloadURL,
        phone: state.user?.phone, // Add the phone number here if needed

        
        addressO: {
          countryT: state.countryT ??{},
          cityT: state.cityT ?? {},
          areaT: state.selectedAreaT ?? {},
          specsN: state.specsN??'',
        },
        reference: state.referenceId??'',
        createdAt:timeNow,
        lastUpdate: timeNow,

      }, {merge: true });

      const userPrfsDocRef = doc(firestore, 'cstPreferences', state.user.uid); // Replace 'userId' with the actual user ID
      await setDoc(userPrfsDocRef, {
        id:state.user?.uid??'',
        name: fullName,
        country: state.selectedCountryN??'',
        city: (state.cityT??{})['en'],
        area: (state.selectedAreaT ?? {})['en'],
        specsN:state.specsN??'',
        specsL: newSpecsNL,
        specsM:{},
        pic: downloadURL,
        phone: state.user?.phone, // Add the phone number here if needed
        email: emailCtr??"",
        subscribedPack: [],
        favChefs: [],
        twinAcc: {},
        lastUpdate: timeNow,
      }, {merge: true });

      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    } finally {
      setUploading(false);
    }
  };


    // Handle logout click
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false); // State for logout confirmation
    const auth = getAuth();

    const handleLogoutClick = () => {
      setShowLogoutConfirm(true);
    };
  
    // Confirm logout
    const confirmLogout = async () => {
      try {
        await signOut(auth);
        alert('You have been logged out.');
        // Redirect to login page if needed
        // window.location.href = '/login';
      } catch (error) {
        console.error('Error logging out:', error);
        alert('Failed to log out. Please try again.');
      } finally {
        setShowLogoutConfirm(false);
      }
    };
  
    // Cancel logout
    const cancelLogout = () => {
      setShowLogoutConfirm(false);
    };


  return (
    <div className="complete-profile-container">
      {/* Logout Confirmation */}
      {showLogoutConfirm && (
        <ScreenModal 
        key={'logOut'}
          open = {true}
          handleClose={cancelLogout}
          maxWidth= '300px'
          maxHeight= 'auto'
          height='150px'
          // handleClose={handleCloseScreenModal}
        >
          <div className="logout-confirmation">
              <p>Are you sure you want to log out?</p>
              
              <div style={{width: '100%',}}>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* Main Title */}

                    <button className="yes-button" onClick={confirmLogout}>Yes</button>
                    <button className="no-button" onClick={cancelLogout}>No</button>                    
                  </Box>
                  

                </div>
              
            </div>
        </ScreenModal>
        
      )}

      <button className="close-button" onClick={handleLogoutClick}>&times;</button>
      
      <h2 className="profile-title">Complete Profile</h2>
      <h3 className="welcome-text">Welcome to NASLST</h3>
      <p className="subtitle">Managing People's shopping lists</p>

      {/* Profile Image */}
      <div className="profile-image-container" onClick={handleImageClick}>
        <img src={profileImage} alt="Profile" className="profile-image" />
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </div>
      <p className="phone-number">{state.user?.phoneNumber}</p>

      {/* Full Name Input */}
      <input
        type="text"
        placeholder="Full Name"
        
        value={fullName}
        onChange={handleFullNameChange}
        className="input-field"
      />

      {/* Username Input */}
      <div className="username-container">
        <input
          type="text"
          placeholder="username"
          value={username}
          onChange={handleUsernameChange}
          className={`input-field ${!usernameAvailable ? 'input-error' : ''}`}
        />
        {checkingUsername && <span className="checking-username">Checking...</span>}
        <FaSearch className="search-icon" />
      </div>
      
      {/* {usernameAvailable? "available":"notavailable"} */}
      {/* Submit Button */}
      <button className="submit-button"
      style={{
        backgroundColor:usernameAvailable? '#FFD31A':'gray'
      }}
      onClick={handleSubmit} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Submit'}
      </button>
    </div>
  );
};

export default CompleteProfile;
