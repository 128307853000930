

 const OverlayWindow = ( {children, closeModal}) => {

    const handleOverlayClick = () => {
        closeModal();
      };
    
        // Prevent modal close on clicking inside the modal content
        const handleModalContentClick = (event) => {
            event.stopPropagation();
          };

    return (<div className="modal-overlay" onClick={handleOverlayClick}>
               <div className="modal-content" onClick={handleModalContentClick}>
                    <button className="dialog-close" onClick={handleOverlayClick}>
                    &times;
                    
                    </button>

                {children}
            </div></div>);
}

export default OverlayWindow;