import React, { useContext, useEffect, useState } from "react";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { firestore, auth } from "../firebase"; // Import Firestore and Auth configurations
import  '../css/AddressForm.css';
import { AppContext } from "../provider/AppContext";
import SearchLocationInput from "../components/GooglePlcasesApi";
import MapComponent from "../components/Map";
import { v1 as uuidv1 } from 'uuid';

const AddressForm = ({createNew = true, editAddressO, onSave}) => {
  // State to track form inputs
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [district, setDistrict] = useState("");
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [savedName, setSavedName] = useState("");
  const [addressType, setAddressType] = useState("work");
  

  // Handle form submission to save address in Firestore
  const handleSaveAddress = async (e) => {
    e.preventDefault();
    const user = auth.currentUser; // Get the current logged-in user
    if (user) {
      const theUuid = editAddressO?.uuid??uuidv1();
      console.log('uuid', theUuid)
      const userDocRef = doc(firestore, "customers", user.uid);

      try {
        // Update user's document with new address
        let addressO = state.currentAddressO??{};
        addressO.uuid = theUuid;
        addressO.district = district;
        addressO.street = street;
        addressO.userId = state.user?.uid;
        addressO.building = building;
        addressO.additionalInfo = additionalInfo;
        addressO.name= savedName;
        addressO.addressType = addressType;
        addressO.timestamp = Timestamp.now();

        updateUserAddress({newAddress: true, addressO: addressO});
        if(onSave) {
          onSave();
        }
        
        // alert("Address saved successfully!");
      } catch (error) {
        console.error("Error saving address: ", error);
        alert("Failed to save address.");
      }
    } else {
      alert("Please log in to save your address.");
    }
  };

  const {state, updateUserAddress} = useContext(AppContext);
  const [formattedAddress, setFormattedAddress] = useState('');
  
  const initState = ()=>{
    if (createNew) {
      setFormattedAddress(state.currentAddress??"");
      setSavedName(state.userModel?.name??"");
      setDistrict(state.currentAddressO?.district??"");
      setStreet(state.currentAddressO?.street??""); 
      setArea((state.currentAddressO?.areaT??{})['en']??""); 
      setCountry((state.currentAddressO?.countryT??{})['en']??""); 
      setCity((state.currentAddressO?.cityT??{})['en']??""); 
    }
  }

  useEffect(() => {
    initState();
  }, [updateUserAddress]);

const [selectedLocation, setSelectedLocation] = useState({
  searchKey:"",
  lat: 30.0444,
  lng: 31.2357,
});

const [openSearch, setOpenSearch] = useState(false);
const openSearchLocation = ()=> {
  setOpenSearch(true);
} 
  return (
    <form className="address-form" >
      <h3>{createNew ? "Create new address":"Edit address"}</h3>

{/* Display current address */}
<div className="address-current">
        
        <div
        style={{
          
          padding:'4px',
          fontSize:'14px',
          fontWeight:'bold',
          borderRadius: '10%',
          width:"100%",
          textAlign:'left'
          
        }} 
        >
      {country} , {city} , {area} 
        </div>
      

        <div style={{
          backgroundColor: 'gray',
          color:'white',
          padding:'4px',
          fontSize:'12px',
          fontWeight:'bold',
          borderRadius: '10%',
          cursor:'pointer'
        }} 
        onClick={()=> {
          openSearchLocation();
        }}
        >
          Change
        </div>
      </div>

      { openSearch ? (
        <div>
        <SearchLocationInput setSelectedLocation={setSelectedLocation} />
      {selectedLocation.searchKey != "" && (
                  <div>
                <MapComponent 
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                />
                <button className="confirm-button"
                    onClick={() => {
                      updateUserAddress({lat: selectedLocation.lat, lng:selectedLocation.lng});
                      
                  }}
                  >
                    Confirm this location
                  </button>
                      </div>
                      ) }
                     </div> 
                      ) : null}
       
       
      {/* Save Address with Label */}
      <label>
        Address name
        <input
          type="text"
          value={savedName}
          onChange={(e) => setSavedName(e.target.value)}
          placeholder="Name"
        />
      </label>

    {/* Flat No. / Floor */}
      <label>
        district
        <input
          type="text"
          value={district}
          onChange={(e) => setDistrict(e.target.value)}
          placeholder="District"
        />
      </label>

      {/* Flat No. / Floor */}
      <label>
      street
        <input
          type="text"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          placeholder="Street"
        />
      </label>

      {/* Building / Street */}
      <label>
      Building, Floor & Flat No.
        <input
          type="text"
          value={building}
          onChange={(e) => setBuilding(e.target.value)}
          placeholder="Building .. Floor ... Flat Num"
        />
      </label>

      {/* Additional Info (Optional) */}
      <label>
        Land mark
        <input
          type="text"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          placeholder="Optional"
        />
      </label>

      {/* Address Type (Work, Home, etc.) */}
      <div className="address-type">
        <label>
          <input
            type="radio"
            value="home"
            checked={addressType === "home"}
            onChange={() => setAddressType("home")}
          />
          House
        </label>
        <label>
          <input
            type="radio"
            value="work"
            checked={addressType === "work"}
            onChange={() => setAddressType("work")}
          />
          Work
        </label>
        <label>
          <input
            type="radio"
            value="other"
            checked={addressType === "other"}
            onChange={() => setAddressType("other")}
          />
          Other
        </label>
      </div>

      

      {/* Save Button */}
      <button type="submit" className="save-button"
      
      onClick={handleSaveAddress}
      >
        Save address
      </button>
    
    </form>
  );
};

export default AddressForm;
