import React, { useState, useEffect , useRef , useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Divider,List, ListItem, ListItemText, Container, Drawer, Box,  Typography, Button, AppBar, Toolbar, TextField, IconButton,
    Modal, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox , useMediaQuery,
      Slide, SvgIcon
 } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

// import { Modal, Box, Typography, Button, Grid, IconButton, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ProductCard from '../widgets/product.js';
import {doc,getDoc, query, collection, where,    getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import { firestore } from '../firebase.js';

import { AppContext } from '../provider/AppContext'; // Import the context
import { useParams } from 'react-router-dom';
import { CartSliderScreen } from './cart.js';
// import { cleanSearchTerm, neatSearchTerm } from '../business/functions.js'
import { Code, Login, Merge } from '@mui/icons-material';
import pattern_web from '../asset/pattern_web.png';  // Import the image like a module
import CheckoutScreen from './checkout.js';
import ScreenModal from '../widgets/popUp.js';
import LoginScreen from './login.js';
import ChatWindow from './chat_old.js';

import Dialog from  '../widgets/Dialog.js';
import ProductPage from './productPage.js';
import LeftDrawer from '../widgets/leftDrawer.js';

import '../OrderHeader.css'; 
import ChatScreen from './chat.js';
import AddNewLocation from './addLocation.js';
import { ReadAddress } from '../constants/constants.js';
import AddressForm from './addAddress.js';
import OrderTrackingPage from './orderPage.js';
import { FaComments } from 'react-icons/fa';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShareIcon from '@mui/icons-material/Share';



// import React from 'react';
// import { Box, Typography, Container } from '@mui/material';

// Mock product data for demonstration


const coverImage = "https://via.placeholder.com/1200x300"; // Replace with actual cover image URL
const logoImage = "https://via.placeholder.com/100"; // Replace with actual logo URL




  
  
const StoreScreen = () => {
  const {activeOrderId, handleActiveOrderId ,state, needLst , setCurrentOrderType  } = useContext(AppContext); // Access the cart state and the function to update the cart
  
  const { username } = useParams();
  const theId = username;
  const TheProduct = {
    name: 'Sample Product',
    price: 19.99,
    image: 'https://via.placeholder.com/400',
  };

  const [products, setProductsL] = useState([]);
  const [searchProducts, setSearchProductsL] = useState([]);
  
  const [lastDocument, setLastDoc] = useState(null);
  const [product, setProduct] = useState(TheProduct);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storeModel, setStoreModel] = useState(null);
  const [currentStoreAddress, setStoreModelAddress] = useState(null);
  
  
  const [orderType, setOrderType] = useState("Deliver");
  const [orderTableN, setTableNum] = useState(null);

  const [activeCode, setActiveCode] = useState(0);
  const [activeMainCode, setActiveMainCode] = useState(0);
  const [activeSubCode, setActiveSubCode] = useState(0);
  const [storeConfig, setStoreConfig] = useState(null);
  // let storeConfig;
  // const [categoriesL, setCategoriesL] = useState([]);
  console.log('here 011')
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Function to toggle the cart drawer
  const toggleCart = (open) => (event) => {
    setIsCartOpen(open);
  };

  const [isChatVisible, setIsChatVisible] = useState(false);

  // Toggle the visibility of the chat window
  const toggleChat = () => {
    if (state.user){
      setIsChatVisible(!isChatVisible);
    } else {
      setActiveModal(<LoginScreen key={"theaLoginScreen"}  />)
    setScreentModalOpen(true)
    }
    
  };

  const closeChat = () => {
    setIsChatVisible(false);
  };



  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Function to toggle the drawer
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };


  const [isProductModalOpen, setProductModalOpen] = useState(false);
  
  const [isScreentModalOpen, setScreentModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  const [req, setReq] = useState('');
  const [rid, setRid] = useState('');

 const readCurrentParm = () => {
  // Access the current URL's query parameters
  const queryParams = new URLSearchParams(window.location.search);
    
  // Get the values of 'req' and 'rid' from the query string
  const reqParam = queryParams.get('req');
  const ridParam = queryParams.get('rid');
  
  // Set the state with the retrieved values
  setReq(reqParam);
  setRid(ridParam);
  if (req == 'order' && ridParam) {
    handleActiveOrderId(ridParam);
  }

 }
  const handleCloseScreenModal = () => {
    setScreentModalOpen(false);
  };

  const setActiveScreenModal = () => {
        setActiveModal(<AddressForm key={"theaNewLocaton"} 
          />)
      setScreentModalOpen(true)
  };

  const setActiveScreenModalProduct = (product) => {
    setActiveModal(
    <ProductPage 
      productK={product}
      storeModel={storeModel}
      key={product?.id??"id" }
      openCart={toggleCart(true)}
      handleClose={handleCloseScreenModal}
      isPackages={storeModel?.activePackage == true}
     />)
    setScreentModalOpen(true)
};



  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  

  const getProductsByCategories = async ({storeId , code}) => {
    if(searchTerm === ''){
      try {
        console.log("getting Store prods ", storeId);
        let listData = [];
        let querySnapshot;
        if (storeModel?.activePackage == true ) {
          console.log('getting packages from storePackages');
          const q = query(collection(firestore, "stores", storeId, 'storePackages')
          , where("pCatL", "array-contains", code)
          , where("active", "==", true)
          
          , orderBy("sortingV", "desc")
          , limit(20)
         );
    
           querySnapshot = await  getDocs(q);
      
           listData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
        } else {
          console.log('getting products from storeProductsO')
            const q = query(collection(firestore, "stores", storeId, 'storeProducts')
            , where("catL", "array-contains", code)
            , where("wareHouse", "==", "mainWareHouse")
            , where("active", "==", true)
            , orderBy("nameT.en", "asc")
            , limit(20)
          );
           querySnapshot = await  getDocs(q);
      
           listData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
        }
        
  
        if (listData.length > 0) {
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length-1])
          // Document data found
          console.log("ProductsL :" +  (listData.length.toString()));
          setProductsL(listData)
          if(listData.length == 20){
            setHasMore(true)
          }
        } else {
          console.log("no prod");
          setLastDoc(null)
          setProductsL([])
          setHasMore(false)
          // No document found with this ID
          // console.log("No such document!");
        }
  
      } catch (err) {
        console.log('error while getting productsL')
        console.log(err.message)
        setError(err.message);
        setLoading(false);
      }
    }
    
  };

  const handleGetProductsByCat =  ({storeId, catCod} )=> {
    setActiveCode(catCod?.code??0);
    setActiveMainCode(catCod?.code??0);
    setActiveSubCode(0);
    setCurrentSubCategories(catCod);
    getProductsByCategories({storeId: storeId, code: catCod?.code??0});
  };

  const handleGetProductsBySubCat =  ({storeId, catCod} )=> {
    if (catCod?.code == 0) {
      setActiveCode(activeMainCode);
      setActiveSubCode(catCod?.code??0);
      getProductsByCategories({storeId: storeId, code: activeMainCode});
    } else {
      setActiveCode(catCod?.code??0);
      setActiveSubCode(catCod?.code??0);
      getProductsByCategories({storeId: storeId, code: catCod?.code??0});
    }
    
  };


useEffect(() => {
  if ((storeModel?.nameT??{})['en']) {
    document.title = "@"+(storeModel?.nameT??{})['en']; // Set the browser tab name to the store name
  }
  readCurrentParm();
}, [storeModel]); 

  useEffect(() => {
    console.log('here 00')
    const fetchStores = async () =>  {
      try {
      console.log("query stores:" +  theId);
      const q = query(collection(firestore, 'stores'), where("username", "==", theId));
      
      const querySnapshot = await  getDocs(q);
      const storeData =    querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      let theStoreModel;
      if ((storeData?.length??0) > 0) {
        console.log("Fetched stores:", storeData);
        const stores  = storeData;
        setLoading(false);
         if ((stores?.length??0) > 0) {
          const store = stores[0]
          theStoreModel = store;
            console.log("found more than one sotres" + (stores.length.toString()));
            setStoreModel(store);
            
        }
      } else {
        console.log("query stores by id:" +  theId);
        const q2 = doc(firestore, 'stores', theId);
        const querySnapshot2 = await  getDoc(q2);
        if((querySnapshot2?.data()?.id??"") != ""){
          theStoreModel = store;
          const store = querySnapshot2.data();
          setStoreModel(store);          
        }        
      }
      if ((theStoreModel?.id??"") != "") {
        console.log('there is a store here');
        let theCurrentStoreAddress = ReadAddress(theStoreModel.addressO);
            // theCurrentStoreAddress =  (theStoreModel.addressO?.areaT??{})['en'] + ", "
            // theCurrentStoreAddress = theCurrentStoreAddress + (theStoreModel.addressO?.district)
            setStoreModelAddress(theCurrentStoreAddress)

          let orderTp = "Deliver"
          if ((theStoreModel?.onlineOptionsL??[]).length > 0) {
            console.log('hasOnline options')
            orderTp = theStoreModel.onlineOptionsL[0]
          }
          
          handleSetOrderType({newType:orderTp, store:theStoreModel});
          if((theStoreModel?.dineInTables??0) > 0) {
            setTableNum(1)
          } else {
            setTableNum(null)
          }
      }
        
        
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
  

    console.log('here 0')
    const fetchStorConfig = async ({storeId}) => {
      try {
        console.log("getting Store config for ", storeId);
        const storeDocRef = doc(firestore, "storesConfig", storeId);
      
        // Fetch the document
        const storeDoc = await getDoc(storeDocRef);
    
        if (storeDoc.exists()) {
          // Document data found
          console.log("Store config:", storeDoc.data());
          setStoreConfig(storeDoc.data())
          // storeConfig = storeDoc.data()
        } else {
          // No document found with this ID
          console.log("No such document!");
        }
    
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    


      
      const fetchByOrders = async () => {
        await fetchStores();
        if ((storeModel?.id??"").length > 0) {
          console.log('there is a store here')
          console.log('now we will get storeConfg')
          console.log('now we will get the categories')
          await fetchStorConfig({storeId : storeModel?.id??""});
          console.log('now we will get the products')
          await getProductsByCategories({storeId:storeModel?.id??"" , code : activeCode})
        } else {
          console.log('empty store')
        }
        }

    fetchByOrders();
    
  }, [loading, username,]);


  
const handleSetOrderType = ({newType, store}) => {
  setOrderType(newType);
  setCurrentOrderType((store??storeModel).id, newType, orderTableN);
}
const allCat = {nameT: {"en":"All", "ar":"All", }, code:0}
  let UseCatType = () => {return storeConfig?.useCatType??true }
  
   let TheCategories = () => {
    let result  = []
    if (UseCatType() === true && (storeConfig?.storeTypeL?.length??0) > 0) {
      storeConfig.activeStoreTypeL = (storeConfig?.storeTypeL??[]).filter(item => item.active);

      result = ((storeConfig?.activeStoreTypeL??[]).map((storeType, index) => (
         (storeType) 
      )));
      // result.push(resultL)
      // result = result 
    }
    return [allCat , ...result]  ;
   };

   const [subCat , setSubCat] = useState([])
   const setCurrentSubCategories =(catO)=> {
    if ((catO?.subCatCodL??[]).length > 0){
      setSubCat([allCat, ...catO?.subCatCodL??[]])
    } else {
      setSubCat([])
    }
    
   }


   
   
  //  const [products, setProducts] = useState(mockProducts);
  const [hasMore, setHasMore] = useState(true);

  // Function to simulate loading more products
  const fetchMoreProducts = () => {
    try{
    if (searchTerm === '') {
      
      setTimeout( async () => {
        let listData = []
        let querySnapshot;
        if (lastDocument != null && storeModel.id != null) {
          if(storeModel.activePackage == true) {

            const q = query(collection(firestore, "stores", storeModel.id, 'storePackages')
          , where("pCatL", "array-contains", activeCode)
          , where("active", "==", true)
          , orderBy("sortingV", "desc")
          , limit(20)
          , startAfter(lastDocument)
         );
    
          querySnapshot = await  getDocs(q);
      
           listData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));  
        }
           else {
            console.log('getting products from storeProductsO')
              const q = query(collection(firestore, "stores", storeModel?.id, 'storeProducts')
              , where("catL", "array-contains", activeCode)
              , where("wareHouse", "==", "mainWareHouse")
              , where("active", "==", true)
              , orderBy("nameT.en", "asc")
              , limit(20)
              , startAfter(lastDocument)
            );
              querySnapshot = await  getDocs(q);
        
              listData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
          }
  
        if ((listData?.length??0) > 0) {
          console.log('addings product to the list')
          setProductsL([...products, ...listData]);
          const latestDoc = querySnapshot?.docs[(querySnapshot?.docs?.length??0) -1]
          setLastDoc(latestDoc)
        } else {
          if (lastDocument != null ) {
            console.log('no products founded')
            setHasMore(false);
          }
  
        }
        
        if (lastDocument != null && listData.length < 20) { // Stop loading after 40 products
          console.log('latest products < 0')
          setHasMore(false);
        }
      }
      }, 30);
    
     
  } else {

    }

  } catch {
    console.log('error')
  }
  };

  const gradients = [
    'linear-gradient(45deg, #FFD700, #FFA500)',
    'linear-gradient(45deg, #FF6347, #FF4500)',
    'linear-gradient(45deg, #32CD32, #228B22)',
    // Add more gradient pairs as needed
  ];

  const [hovered, setHovered] = useState(false);


  const headerStyle2 = {
    position: 'absolute',  // Make Header absolutely positioned
    top: 0,
    left: 0,
    right: 0,
    padding: '0px',
    
    backgroundColor: 'transparent',  // Set background color to make it distinct
    boxShadow: 
    hovered
      ? '0 8px 10px rgba(0, 0, 0, 0.6)'  // Shadow when hovered
      : '0 2px 4px rgba(0, 0, 0, 0.1)'
    ,  // Add shadow
    zIndex: 2,  // Ensure it overlays Header23
  };



  // Header content
  const [showAppBar, setShowAppBar] = useState(true);  // Control visibility of AppBar
const [lastScrollY, setLastScrollY] = useState(0);   

const handleScroll = () => {
  const currentScrollY = window.scrollY;

  if (currentScrollY > (lastScrollY - 1)) {
    // If scrolling down, hide the AppBar
    setShowAppBar(false);
  } else {
    // If scrolling up, show the AppBar
    setShowAppBar(true);
  }

  setLastScrollY(currentScrollY);  // Update the last scroll position
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [lastScrollY]);



const [searchTerm, setSearchTerm] = useState('');
const cleanSearchTerm = (searchTerm) => {
  // Step 1: Trim leading/trailing spaces
  const trimmedTerm =  (" " + searchTerm + " ").toLowerCase()
  .replaceAll("أ", "ا")
  .replaceAll("ي ", "ى ")
  .replaceAll("ة", "ه")
  .replaceAll("إ", "ا")
  .replaceAll("آ", "ا")
  .replaceAll("   ", " ")
  .replaceAll("  ", " ")
  .replaceAll(" and ", " ")
  .replaceAll(" And ", " ")
  .replaceAll(" & ", " ")
  .replaceAll(" و ", " ")
  .trim();

  // Step 2: Split the string by space into an array
  const splitTerms = trimmedTerm.split(' ');

  // Step 3: Filter out any empty strings caused by multiple spaces
  const cleanedTerms = splitTerms.filter(term => term !== '');
  
  // Step 4: Return the cleaned array of terms
  return cleanedTerms;
};



const searchStoreProducts = async (term) => {
  if (term === '') {

    return;
  }
  console.log(cleanSearchTerm(term))

  setLoading(true);
  try {
    // Query Firestore to search stores by name

    let storeResults = []
    let storeResults2 = []
    let storeResults3 = []
    

    // neets to create index to search with this
    if (false  && storeResults2.length == 0) {
      const q3 = query(
        collection(firestore, 'stores', storeModel.id, 'storePackages'),
          where('active', '==', true),
          where('nameT.en', '>=', term ),
          where('nameT.en', '<=', term + '\uf8ff'),// Range search for partial matches
          limit(20)  // 'stores' is your collection in Firestore
        );
  
        const querySnapshot3 = await getDocs(q3);
         storeResults3 = querySnapshot3.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
    }

    if (storeResults2.length == 0) {
      const q = query(
        collection(firestore, 'stores', storeModel.id, 'storePackages'),
          where('active', '==', true),
          where('tags', 'array-contains-any', cleanSearchTerm(term)),
          limit(20)  // 'stores' is your collection in Firestore
        );

  
        const querySnapshot = await getDocs(q);
        storeResults = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
    }
    const allList = [...storeResults2,  ...storeResults3, ...storeResults, ]
  //   const uniqueValues = [...new Set(allList)];
    const uniqueValues = [
      ...new Map(allList.map(item => [item.id, item])).values()
    ];
      // Step 2: Sort the values
      const sortedValues = uniqueValues.sort((a, b) => {
      // If 'a' matches the search key, place it first
      if (a.nameT['en'] === (term)) return -1  
      // If 'b' matches the search key, place it first
      if (b.nameT['en'] === (term)) return 1;
      // If 'a' matches the search key, place it first
      if (a.nameT['en'].includes(term)) return -1  
      // If 'b' matches the search key, place it first
      if (b.nameT['en'].includes(term)) return 1;
      
      if ((a.username??"").includes(term)) return -1;
      // Otherwise, sort alphabetically
      if ((b.username??"").includes(term)) return 1;

      if ((a.tagsL??[]).includes(term)) return -1;
      // Otherwise, sort alphabetically
       if ((b.tagsL??[]).includes(term)) return 1;

      return (a.id).localeCompare(b?.id);
      });
      

    setSearchProductsL(sortedValues);

  } catch (error) {
    console.error('Error fetching stores:', error);
  } finally {
    setLoading(false);
  }
};

const handleInputChange = (e) => {
  const value = e.target.value;
  setSearchTerm(value);
  searchStoreProducts(value);  // Trigger Firestore query on input change
};



const [checkoutVisible, setCheckoutVisible] = useState(false);
const handleCheckoutClose = () => {
  setCheckoutVisible(false); // Close the checkout screen
};

const isSmallScreen = useMediaQuery('(max-width: 800px)'); // Adjust screen size for responsiveness

const storeName = (storeModel?.nameT??{})['en']
const  [cartQuantity , setCartQuantity ] = useState(0)
const  [cartTotal , setCartTotal ] = useState(0)

useEffect(() => {
  const fetchCart = async () =>  {
    console.log('fetchCart');
    let result = 0;
    setCartQuantity(0);
    (state.needLst?.storeProductsOL??[]).map(element => {
      if (element?.storeId === storeModel?.id) {
        const q = element?.productsOL?.length??0;
        setCartQuantity(q);
        (element?.productsOL??[]).map(productO => {
          result = result + (productO.quantity * productO.price);
         });
      } else {
        setCartQuantity(0);
        console.log('no quantity');
      }
    });
    setCartTotal(result);
  }
  console.log('done check');
  fetchCart();

}, [storeModel, state, needLst]);


const [isAddressModalOpen, setIsAddressModalOpen] = useState(false); // To control modal visibility

    // Function to open the modal
    const openAddressModal = () => {
      setIsAddressModalOpen(true);
    };
  
    // Function to close the modal
    const closeAdressModal = () => {
      setIsAddressModalOpen(false);
    };


    const handleShare = () => {
      const storeUsername = storeModel?.username??storeModel?.id
      const storeLink = 'https://www.order.naslst.com/b/' + storeUsername; // Replace with your store link
      const storeName = (storeModel?.nameT??{})['en']; // Replace with your store name
    
      if (navigator.share) {
        // Check if the Web Share API is supported
        navigator.share({
          title: storeName,
          text: `Check out this store list: ${storeName}`,
          url: storeLink,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.error('Error sharing:', error));
      } else {
        // Fallback for browsers that don't support the Web Share API
        navigator.clipboard.writeText(storeLink)
          .then(() => {
            alert('Store link copied to clipboard. You can now share it manually!');
          })
          .catch((error) => console.error('Failed to copy link:', error));
      }
    };

    
return (
    <div  className="background-section">
      {/* <div
      style={headerStyle2}
      onMouseEnter={() => setHovered(true)}  // Add shadow on hover
      onMouseLeave={() => setHovered(false)} // Remove shadow when not hovered
    >
      <h1>Header</h1>
    </div> */}
    <div style={headerStyle2} 
    onMouseEnter={() => setHovered(true)}  // Add shadow on hover
    onMouseLeave={() => setHovered(false)} // Remove shadow when not hovered
    >
      {isAddressModalOpen && <AddNewLocation closeModal={closeAdressModal} isOpen={isAddressModalOpen} />}
      <ScreenModal 
        key={"screenModalAddress"}
        open = {isScreentModalOpen}
        handleClose={handleCloseScreenModal}
      >
        {activeModal}
      </ScreenModal>

      <CartSliderScreen key={"cartSlidder"} 
      openChat = {toggleChat}
      isOpen={isCartOpen} 
      toggleDrawer={toggleCart} 
      />
    
      <Drawer key={"drawer"} anchor="right" 
        open={activeOrderId != null} 
        onClose={() => handleActiveOrderId(null)}>
          <Box sx={{
              width: 400,
              padding: 2,
              backgroundColor: '#f5f5f5',
              height: '100%',
            }} >
            <OrderTrackingPage key={"checkout"} 
            storeProductO= {state.checkOutStoreProduct}  
            orderId = {activeOrderId}
            closeOrder={() => handleActiveOrderId(null)} />
          </Box>
      </Drawer>

    {/* <Header StoreModel={storeModel}  key= {"storeHeader1"}/> */}
    <Slide direction="down" 
    in={showAppBar}
    >
      <AppBar 
      // position= {!isSmallScreen? "fixed": "sticky"} 
      position= {"fixed"} 
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }}>
        <Toolbar>
          {/* Menu Button to open drawer */}
      <IconButton
        onClick={toggleDrawer(true)}
      >
        <MenuIcon  style={{color:'white'}} fontSize="large" />
      </IconButton>
          <Typography variant="h6" 
          style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img 
              width={40} 
              src={storeModel?.logo ?? "https://via.placeholder.com/50"} 
              alt="Logo" 
              style={{ 
                marginRight: '10px', 
                borderRadius: '10%'  // Circular image
              }} 
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
              style={{ 
                marginBottom: '-8px'  }}
              >{(storeModel?.nameT ?? {})['en']}</span>
              <span  style={{ 
                 marginBottom: '2px' ,
                fontSize: '0.6em', color: 'gray' }}>@{storeModel?.username ?? 'Unknown User'}</span>  {/* Username */}
            </div>
            
          </div>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search"
            onChange={handleInputChange}
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '5px', marginRight: '10px' }}
          />
          {/* <IconButton color="inherit">
            <SearchIcon />
          </IconButton> */}
          {/* View Order Button */}
          {cartQuantity > 0 ? (<div className="view-order-wrapper">
            <button className="view-order-button"
            onClick={toggleCart(true)}
            >
              <span className="order-count">
              <Typography style={{
                      color:'black'}}> 
                    {cartQuantity}
                      </Typography>
                </span>
              <span className="order-text">View order</span>
              <span className="order-price"> {cartTotal/100} {" "} {storeModel?.currency??"LE"}</span>
            </button>
      </div>): (
         <IconButton
        onClick={toggleCart(true)}
        // style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}
        aria-label="cart"
        style={{
          color:'gray'
        }}
      >
        <ShoppingCartIcon fontSize="large" />

      </IconButton> 
      )} 
        </Toolbar>
        </AppBar>
        
    </Slide>
    </div>
    
    
      
    { searchTerm === ''? (
      
      <div>        
      {/* <Header23 key= {"storeHeader2"} storeModel = {storeModel}/> */}
      <Box>
        {/* Cover Photo */}
        <Box
          sx={{
            height: isSmallScreen?200:300,
            backgroundImage: `url(${storeModel?.img??coverImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
          }}
        >
          {/* Optional overlay for dimming the cover photo */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay with transparency
            }}
          />
        </Box>
  
        {/* Logo, Store Name, and Buttons */}
        <Container sx={{ marginTop: '10px', position: 'relative', zIndex: 1 }}>
  <Grid 
    container
    alignItems="center"
    justifyContent="space-between"
  >
    {/* Logo and Name Container */}
    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
      {/* Logo */}
      <Box
        component="img"
        src={storeModel?.logo ?? logoImage}
        alt="Store Logo"
        sx={{
          width: isSmallScreen ? 50 : 100,
          height: isSmallScreen ? 50 : 100,
          borderRadius: '50%',
          border: '4px solid white',
          backgroundColor: 'white',
          marginRight: 2,
        }}
      />

      {/* Store Name and Username */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" color="textPrimary">
          {storeName}
        </Typography>
        
        <Typography variant="body2" color="textSecondary">
          @{storeModel?.username ?? ""}
        </Typography>

        {/* Social Media Icons */}
        <Grid item sx={{ 
          padding:'0px',
          display: 'flex', alignItems: 'center', 
           }}>
          {storeModel?.fb &&(<IconButton style={{paddingLeft:0}}
            onClick={() => window.open(storeModel.fb, '_blank')}
          >
            <FacebookIcon sx={{ color: '#4267B2' }} />
          </IconButton>)}
          {storeModel?.inst && (<IconButton 
          onClick={() => window.open(storeModel.inst, '_blank')}
          >
            <InstagramIcon sx={{ color: '#E4405F' }} />
          </IconButton>)}
          
          {storeModel?.loc&&(<IconButton 
          onClick={() => window.open(storeModel.loc, '_blank')}
          >
            <LocationOnIcon sx={{ color: '#F44336' }} />
          </IconButton>)}
        </Grid>
      </Box>
    </Grid>

    {/* Subscribe Button */}
    <Grid hidden={true} item>
      <Button
        onClick={setActiveScreenModal}
        variant="contained"
        color="primary"
      >
        Subscribe
      </Button>
    </Grid>

    <IconButton onClick={handleShare}>
    <ShareIcon sx={{ color: '#4267B2' }} />
  </IconButton>
  </Grid>
</Container>



        <Container sx={{ marginTop: '10px', position: 'relative',  }}>
        <Grid 
          container alignItems="center" >
            {(storeModel?.onlineOptionsL??[]).map((type, index) => 
                    (<Box key={index}  
                        sx={{
                          // width: 70,
                          borderRadius: '10%',
                          border: orderType == type? '4px solid #FFD31A': null,
                          backgroundColor: 'rgba(0,0,255,0.05)',
                          color: '#9897ad',
                          marginLeft:'5px',
                          marginRight: '5px',
                          marginBottom: '5px',
                          padding: '10px',
                          cursor: 'pointer'
                        }}
                        
                        onClick={() => handleSetOrderType({newType:type})}
                        >
                       
                      {type}
                      
                      </Box>))} 
                      <Box 
                      
                      sx={{
                          // width: 70,
                          borderRadius: '10px',
                          // border: '4px solid #FFD31A' ,
                          backgroundColor: '#9897ad',
                          color: '#9897ad',
                          marginLeft:'5px',
                          marginRight: '5px',
                          marginBottom: '5px',
                          padding: '10px',
                          cursor: 'pointer',
                          // width:'100px',
                          // maxHeight:'30px',
                          maxWidth:'300px'
                        }} >
                        
                          <div onClick={()=> {
                            if (orderType == "Deliver") {
                              openAddressModal();
                            }
                            
                          }}>
                          
                          <Typography 
                           style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 1,  // Limits to 2 lines
                            textOverflow: 'ellipsis',
                          }}
                          variant='body1' color="white" >
                            {/* {state.userModel?.name??""}{" - "} */}
                            {orderType == "Deliver"? "Deliver to":""}
                            
                            {orderType == "Pickup" || orderType == "Dine In" ? "Location":""}
                            {" "}
                            {orderType == "Deliver"? (state.currentAddress??"Select Your Address"):""}  
                            {orderType == "Pickup" || orderType == "Dine In"? (currentStoreAddress??"Select Your Address"):""}  
                          </Typography>
                          </div>
                      
                      </Box>
          </Grid>
        
          
        </Container>
        
      </Box>

      
      


<div>

<div
        style={{
          display: 'flex',
          overflowX: 'auto', // Enables horizontal scrolling
          whiteSpace: 'nowrap', // Prevents items from wrapping to the next line
          padding: '10px',
          // backgroundColor: '#ffe08',
          backgroundImage: 'linear-gradient(to bottom,  #04032B, black)', 
          
        }}
      >
        {
        TheCategories().map((category, index) => (
          <Button 
          key= {index}
          onClick={() => handleGetProductsByCat({storeId:storeModel?.id??"" , catCod : category }) }
            style={{
              margin: '0 10px',
              color: '#ffffff',
              // fontWeight: 'bold',
              // backgroundColor: '#ffe08',
              backgroundImage: activeMainCode == category.code ? 'linear-gradient(to bottom, #04032B, #ffffff )' : null,
              flexShrink: 0, // Ensures the buttons don’t shrink when the container is too small
            }}
          >
            {(category?.nameT??{})['en']}
          </Button>
        ))}
        
        
      </div>

      <div
        style={{
          display: 'flex',
          overflowX: 'auto', // Enables horizontal scrolling
          whiteSpace: 'nowrap', // Prevents items from wrapping to the next line
          padding: subCat.length > 0 ?'5px':'0px',
          // backgroundColor: '#ffe08',
          backgroundImage: 'linear-gradient(to bottom,  #04032B, black)', 
          
        }}
      >
{
        subCat.map((category, index) => (
          <Button 
          onClick={() => handleGetProductsBySubCat({storeId:storeModel?.id??"" , catCod : category}) }

            key= {index}
            style={{
              margin: '0 10px',
              color: '#ffffff',
              // fontWeight: 'bold',
              // backgroundColor: '#ffe08',
              backgroundImage: activeSubCode == category.code ? 'linear-gradient(to bottom, #04032B, #ffffff )' : null,
              flexShrink: 0, // Ensures the buttons don’t shrink when the container is too small
            }}
          >
            {(category?.nameT??{})['en']}
          </Button>
        ))}
      </div>
      
</div>
      
      
      </div>
      
      ) : (<div/ > ) }
      
      {(searchTerm !=="") ? (<div style={{height:60}}></div>): null}

      {/* Add the rest of your page content */}
      {/* <CategoryBar2 storeConfig = {storeConfig}/> */}
      
      {/* <div style={{ padding: '20px' }}>
        <ProductGrid storeModel = {storeModel} />
      </div> */}
      <div style={{padding: '20px' }}>
        <InfiniteScroll
        dataLength={(searchTerm == ''? products:searchProducts).length}
        next={fetchMoreProducts}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage= {<p style={{ textAlign: 'center' }} >Powered by NASLST</p>}
      >
        <Grid container spacing={2}>
          {(searchTerm == ''?products:searchProducts).map((product) => (
            <Grid  item xs={6} sm={6} md={3} lg={2} key={product.id}>
              <ProductCard key={product.id} 
              productK={product} 
              isPackages ={storeModel?.activePackage == true}
              storeModel={storeModel} 
              // onItemTapped = {handleOpenProductModal}
              onItemTapped={() => setActiveScreenModalProduct(product) }
              // onIncrease={() => handleOpenProductModal({prod:product}) }
              // onDecrease= {handleOpenProductModal}
              />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
      </div>
      


      <div>
      
      
      
    </div>


      <div>
      

      {/* Left Drawer */}
      <LeftDrawer key={'leftDrawer3'} isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      
      {/* Rest of your page content */}
      {/* <div style={{ padding: '20px' }}>
        <h1>Welcome to the Home Page</h1>
        <p>Content goes here...</p>
      </div> */}
    </div>

      <div hidden ={false}>
      <Button 
        variant="contained"
        color="primary"
        onClick={toggleChat}
        sx= {{ 
          borderRadius:'50%',
          backgroundColor: 'white',
          color:'black',
          position: 'fixed', 
          bottom: isSmallScreen? '60px': '60px', 
          right: isSmallScreen?'20px':'90px', zIndex: 200 
        }}
      >
        <FaComments size={30} color="#FFD31A" />
        {/* Chat */}
      </Button>

      {/* Chat Window */}
      
        {state.user && (<ChatScreen 
        storeModel={storeModel} 
        isVisible={ isChatVisible} 
        closeChat={closeChat} />)}
    </div >
      
      
    </div>
    
  );
};

//   return (
//     <div>
//       <Header />
//       <Header2 />
//       <CategoryBar2 />
//       <div style={{ padding: '20px' }}>
//         <ProductGrid />
//       </div>
//     </div>
//   );
// };

export default StoreScreen;
