import React , { useState , useRef, useEffect} from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../constants/constants";

const MapComponent = ({ selectedLocation , setSelectedLocation }) => {
  const [mapCenter, setMapCenter] = useState(selectedLocation); 
  const [centerChanged, setCenterChanged] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
  });
  const [localSelectedLocation, setLocalSelectedLocation] = useState({
    lat: selectedLocation.lat,
    lng: selectedLocation.lng,
  });
  // const mapRef = React.useRef();
  const mapRef = useRef(null)

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  

    // Function to set marker at the center of the map
    const moveToCenter = () => {
      if (mapRef.current) {
        const currentCenter = mapRef.current.getCenter().toJSON();
        setSelectedLocation({
          lat: currentCenter.lat,
          lng: currentCenter.lng,
        });
        setLocalSelectedLocation({
          lat: currentCenter.lat,
          lng: currentCenter.lng,
        });
        setCenterChanged(false);
      }
    };
  
    // Check if marker and center are equal (used to show or hide the "Here" button)
    const isMarkerInCenter =
    localSelectedLocation.lat === mapRef.current?.getCenter().lat() &&
    localSelectedLocation.lng === mapRef.current?.getCenter().lng();

    
    const mapChanged = () =>{
      if (centerChanged == false) {
        setCenterChanged(true);
      }
    }



  // Function to update map center to a specific lat/lng
  const updateMapLocation = (lat, lng) => {
    if (mapRef.current) {
      mapRef.current.setCenter({ lat, lng });
      setSelectedLocation({ lat, lng }); // Optionally, update the marker position as well
      setLocalSelectedLocation({ lat, lng });
    }
  };

  useEffect(() => {
    console.log('updateMapLocation called 1');
    if (selectedLocation.lat != localSelectedLocation.lat || 
      selectedLocation.lng != localSelectedLocation.lng ){
        console.log('updateMapLocation called 2');
      updateMapLocation(selectedLocation.lat, selectedLocation.lng);
    }
  }, [selectedLocation]); 


  // Only update mapCenter if it's different from the current center
  const handleCenterChanged = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter().toJSON();

      // Check if the new center is different from the current mapCenter
      if (
        newCenter.lat !== mapCenter.lat ||
        newCenter.lng !== mapCenter.lng
      ) {
        mapChanged();
        setMapCenter(newCenter);
      }
    }
  };

  if (loadError) return "Error";
  if (!isLoaded) return "Maps";


  return (
    <div style={{ position: "relative" }}>
      {/* Google Map */}
      <div style={{ marginTop: "10px" }}>
        <GoogleMap
          mapContainerStyle={{
            height: "300px",
            width: "100%",
          }}
          center={localSelectedLocation}
          zoom={16}
          onLoad={onMapLoad}
          onCenterChanged={handleCenterChanged}
        >
          {/* Marker */}
          <MarkerF
            position={localSelectedLocation}
            icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
          />
        </GoogleMap>

        {/* Pop-up point (static) in the center of the map */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "6px",
            height: "6px",
            backgroundColor: "red",
            borderRadius: "50%",
            zIndex: 100,
          }}
        ></div>
      </div>

      {/* Show button if the marker is not in the center */}
      {(centerChanged) && (
        <button
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#ffc107",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            color: "white",
            fontSize: "16px",
            cursor: "pointer",
            zIndex: 1000,
          }}
          onClick={moveToCenter}
        >
          Here
        </button>
      )}
    </div>
  );

};

export default MapComponent;
