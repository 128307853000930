// OrderProgressBar.jsx
import React from 'react';
import '../css/OrderProgressBar.css'; // Import the CSS for styling
import delivery from '../asset/deliverys.png';  // Import the image like a module

const OrderProgressBar = ({ percentage, orderModel }) => {
  
  // Define icons for each stage

  const stages = orderModel?.orderType == "Deliver" ? [
    { label: 'Sent', icon: '↗️' },
    { label: 'Ready', icon: '🏬' },
    { label: 'On delivery', icon: '🏍️' },
    { label: 'Delivered', icon: '📦' },
  ] : [
    { label: 'Sent', icon: '↗️' },
    { label: 'Ready', icon: '🏬' },
    // { label: 'on Delivery', icon: '🏍️' },
    { label: 'Delivered', icon: '📦' },
  ];

  // Determine which stage is active based on the percentage
  const activeStage = percentage <= 33 ? 0 : percentage <= 66 ? 1 : 2;

  return (
    <div className="order-progress-container">
      <div className="progress-bar-background">
        <div
          className="progress-bar-fill"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>

      <div className="progress-icons">
        {stages.map((stage, index) => (
          <div key={index} className={`stage-icon ${index <= activeStage ? 'active' : ''}`}>
            <span className="icon">{stage.icon}</span>
            <span className="label">{stage.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderProgressBar;
