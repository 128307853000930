

const cleanSearchTerm = (searchTerm) => {
    // Step 1: Trim leading/trailing spaces
    const trimmedTerm = searchTerm.trim().toLowerCase();
  
    // Step 2: Split the string by space into an array
    const splitTerms = trimmedTerm.split(' ');
  
    // Step 3: Filter out any empty strings caused by multiple spaces
    const cleanedTerms = splitTerms.filter(term => term !== '');
  
    // Step 4: Return the cleaned array of terms
    return cleanedTerms;
  };

  const neatSearchTerm = (searchTerm ) => {
    // Step 1: Trim leading/trailing spaces
    const trimmedTerm = searchTerm.trim().toLowerCase();
  
    // Step 4: Return the cleaned array of terms
    return trimmedTerm;
  };


//   export { neatSearchTerm, cleanSearchTerm};
const currentLang = () => navigator.language || navigator.userLanguage;




export const readPackageAddons = (addOnOptionsL) => {
  let result = "";

  addOnOptionsL?.forEach((options) => {
    options?.addOns?.forEach((addons) => {
      if (addons.selected) {
        const addonName = addons.nameT?.['en'] || "";
        if (result.length === 0) {
          result = addonName;
        } else {
          result += " - " + addonName;
        }
      }
    });
  });

  return result;
};