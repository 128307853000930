import React, { useState, useEffect, useContext } from 'react';
import { IconButton, TextField, List, ListItem, Typography, CircularProgress } from '@mui/material';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { firestore } from '../firebase';  // Ensure your Firebase config is imported
import { useNavigate } from 'react-router-dom';  // Import React Router's useNavigate
import { CartSliderScreen } from './cart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import pattern_web from '../asset/pattern_web.png';  // Import the image like a module
import '../css/homePage.css';
import AddNewLocation from './addLocation';
import ScreenModal from '../widgets/popUp';
import LoginScreen from './login';
import { AppContext } from '../provider/AppContext';

const StoreSearch = () => {
  const { state } = useContext(AppContext);  // Access global state
  const [searchTerm, setSearchTerm] = useState('');
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const cleanSearchTerm = (searchTerm) => {
    // Step 1: Trim leading/trailing spaces
    const trimmedTerm =  (" " + searchTerm + " ").toLowerCase()
    .replaceAll(" @", " ")
    .replaceAll("أ", "ا")
    .replaceAll("ي ", "ى ")
    .replaceAll("ة", "ه")
    .replaceAll("إ", "ا")
    .replaceAll("آ", "ا")
    .replaceAll("   ", " ")
    .replaceAll("  ", " ")
    .replaceAll(" and ", " ")
    .replaceAll(" And ", " ")
    .replaceAll(" & ", " ")
    .replaceAll(" و ", " ")
    .trim();
  
    // Step 2: Split the string by space into an array
    const splitTerms = trimmedTerm.split(' ');
  
    // Step 3: Filter out any empty strings caused by multiple spaces
    const cleanedTerms = splitTerms.filter(term => term !== '');
  
    // Step 4: Return the cleaned array of terms
    return cleanedTerms;
  };

  const neatSearchTerm = (searchTerm ) => {
    // Step 1: Trim leading/trailing spaces
    const trimmedTerm =  (" " + searchTerm + " ").toLowerCase()
    .replaceAll(" @", " ")
    .replaceAll("أ", "ا")
    .replaceAll("ي ", "ى ")
    .replaceAll("ة", "ه")
    .replaceAll("إ", "ا")
    .replaceAll("آ", "ا")
    .replaceAll("   ", " ")
    .replaceAll("  ", " ")
    .replaceAll(" and ", " ")
    .replaceAll(" And ", " ")
    .replaceAll(" & ", " ")
    .replaceAll(" و ", " ")
    .trim();
  
    // Step 4: Return the cleaned array of terms
    return trimmedTerm;
  };
  // Function to query Firestore based on the search term
  const searchStores = async (term) => {
    if (term === '') {
      setStores([]);  // If the search term is empty, reset the stores list
      return;
    }
    console.log(cleanSearchTerm(term))

    setLoading(true);
    try {
      // Query Firestore to search stores by name

      let storeResults = []
      let storeResults2 = []
      let storeResults3 = []
      

      const q2 = query(
        collection(firestore, 'stores'),
        where('active', '==', true),
        where('username', '>=', neatSearchTerm(term)),
        where('username', '<=', neatSearchTerm(term) + '\uf8ff'),// Range search for partial matches
        limit(20)  // 'stores' is your collection in Firestore
      );

      const querySnapshot2 = await getDocs(q2);
      storeResults2 = querySnapshot2.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      
      if(storeResults2.length == 0) {
        const q3 = query(
            collection(firestore, 'stores'),
            where('active', '==', true),
            where('nameT.en', '>=', term ),
            where('nameT.en', '<=', term + '\uf8ff'),// Range search for partial matches
            limit(20)  // 'stores' is your collection in Firestore
          );
    
          const querySnapshot3 = await getDocs(q3);
           storeResults3 = querySnapshot3.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
      }

      if (storeResults2.length == 0) {
        const q = query(
            collection(firestore, 'stores'),
            where('active', '==', true),
            where('tagsL', 'array-contains-any', cleanSearchTerm(term)),
            limit(20)  // 'stores' is your collection in Firestore
          );

    
          const querySnapshot = await getDocs(q);
          storeResults = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
      }
      const allList = [...storeResults2,  ...storeResults3, ...storeResults, ]
    //   const uniqueValues = [...new Set(allList)];
      const uniqueValues = [
        ...new Map(allList.map(item => [item.id, item])).values()
      ];
        // Step 2: Sort the values
        const sortedValues = uniqueValues.sort((a, b) => {
        // If 'a' matches the search key, place it first
        if (a.nameT['en'] === (term)) return -1  
        // If 'b' matches the search key, place it first
        if (b.nameT['en'] === (term)) return 1;
        // If 'a' matches the search key, place it first
        if (a.nameT['en'].includes(term)) return -1  
        // If 'b' matches the search key, place it first
        if (b.nameT['en'].includes(term)) return 1;
        
        if ((a.username??"").includes(term)) return -1;
        // Otherwise, sort alphabetically
        if ((b.username??"").includes(term)) return 1;

        if ((a.tagsL??[]).includes(term)) return -1;
        // Otherwise, sort alphabetically
         if ((b.tagsL??[]).includes(term)) return 1;

        return (a.id).localeCompare(b?.id);
        });
        

      setStores(sortedValues);

    } catch (error) {
      console.error('Error fetching stores:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle search input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    searchStores(value);  // Trigger Firestore query on input change
  };

    // Handle store click to navigate to the store's page
    const handleStoreClick = (username) => {
        navigate(`/b/${username}`);  // Navigate to /b/:username
      };

  const [isCartOpen, setIsCartOpen] = useState(false);

  // Function to toggle the cart drawer
  const toggleCart = (open) => (event) => {
    setIsCartOpen(open);
  };

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false); // To control modal visibility

  // Function to open the modal
  const openAddressModal = () => {
    // toggleDrawer(false);
    setIsAddressModalOpen(true);
  };

  // Function to close the modal
  const closeAdressModal = () => {
    setIsAddressModalOpen(false);
  };


  const [isLoginOpen, setLoginScreen] = useState(false); // To control modal visibility

  // Function to open the modal
  const openLoginScreen = () => {
    // toggleDrawer(false);
    setLoginScreen(true);
  };

  // Function to close the modal
  const closeLoginScreen = () => {
    setLoginScreen(false);
  };

  return (
    <div style={{ padding: '2px' }}>
        {isAddressModalOpen && 
        <AddNewLocation closeModal={closeAdressModal} isOpen={isAddressModalOpen} />}
        <ScreenModal open={isLoginOpen} handleClose={closeLoginScreen}>
          <LoginScreen></LoginScreen>
        </ScreenModal>
      
      
      {/* <CartSliderScreen isOpen={isCartOpen} toggleDrawer={toggleCart} /> */}
      
      <div className="container">
      <header className="header">
        <h1 className="logo">NASLST</h1>
        <div className="auth-buttons" onClick={openLoginScreen}>
          {/* <button className="login-btn">Verify account</button> */}
          {(state.userModel?.id??"")==""?(<button className="signup-btn">Verify now</button>): (<div className="profile-btn">{state?.userModel?.name??""}</div>)}
        </div>
      </header>
      <main className="content">
        <div className="overlay">
          <h2>Looking for business nearby?</h2>
          {/* <input type="text" placeholder="Enter your location" className="address-input" /> */}

          <input type="text"
          className="address-input"
          placeholder="Enter business name"
          value={searchTerm}
          onChange={handleInputChange}
        
          />
          <div>
          

        {/* Loading Spinner */}
        {loading && <CircularProgress style={{ marginTop: '20px' }} />}
        {/* Display the list of stores */}
        <List 
        
        >
          {stores.map((store) => (
            <ListItem 
            className='signup-btn'
              // style={{
              //   backgroundColor:'#e0f7fa',
              //   width:'100%',
              //   borderRadius:'10%',
              //   cursor:'pointer'

              // }}
              key={store.id}
              button =  {store.id.toString()}
              onClick={() => handleStoreClick(store.username??store.id)}  // Trigger navigation on click
            >
              <Typography variant="body1">{(store.nameT??{})['en']}</Typography>
            </ListItem>
          ))}
        </List>

          </div>
          <div className="address-options">
            <a onClick={openAddressModal} href="#">Share location</a>
            <a onClick={openLoginScreen} href="#">
              {(state.userModel?.id??"") == "" ?
              "Log in for saved addresses" : ("Hello " + state.userModel?.name??"")}
              </a>
          </div>
        </div>
      </main>
    </div>

    </div>
  );
};

export default StoreSearch;
