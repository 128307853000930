// MapComponent.jsx
import React from 'react';
import { GoogleMap, Marker, MarkerF, useLoadScript } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAPS_KEY } from '../constants/constants';
import delivery from '../asset/deliverys.png';  // Import the image like a module

const MapComponentTrack = ({ location }) => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY });

  // Define map options to disable unwanted controls
  const mapOptions = {
    disableDefaultUI: true, // Disable all default UI controls
    zoomControl: false, // Enable zoom control

  };


  if (!isLoaded) return <div>Loading map...</div>;

  const icon = {
    url: delivery, // URL of the image
    scaledSize: new window.google.maps.Size(40, 40), // Adjust the size (width, height) in pixels
  };

  return (
    <GoogleMap 
        center={location} zoom={16} 
        mapContainerStyle={{ width: '100%', height: '300px' }}
        options={mapOptions}
    >
      <MarkerF position={location} 
      icon={icon}
      />
    </GoogleMap>
  );
};

export default MapComponentTrack;
