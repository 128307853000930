import React, { useState } from "react";
import PaymentUI from "../screen/paymentUI";

function PaymentOverlay({ paymentUrl , isModalOpen , closeModal }) {
  

  const handleClose = () => {
    closeModal();
  }

  return (
    <div>
      {isModalOpen && (
        <div className="modal-overlay-pay">
          <div className="modal-content-pay">
            <button onClick={handleClose}>Close</button>
            <iframe
              src={paymentUrl}
              width="100%"
              height="500px"
              title="Payment"
              frameBorder="0"
            />

          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentOverlay;
