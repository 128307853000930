import React, { useState, useEffect , useRef , useContext } from 'react';

import { List, ListItem, ListItemText,  Drawer, Box, 
    
 } from '@mui/material';
import ScreenModal from '../widgets/popUp';
import LoginScreen from '../screen/login';
import { getAuth, signOut } from 'firebase/auth';
import { AppContext } from '../provider/AppContext';


const LeftDrawer = ({ isOpen, toggleDrawer }) => {
    // List of options in the drawer
  const {state} = useContext(AppContext);

  const [isScreentModalOpen, setScreentModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  const handleCloseScreenModal = () => {
    setScreentModalOpen(false);
  };

  const setActiveScreenModal = () => {
    toggleDrawer(false);
    setActiveModal(<LoginScreen key={"login"} />);
    setScreentModalOpen(true);
    
};

// Handle logout click
const [showLogoutConfirm, setShowLogoutConfirm] = useState(false); // State for logout confirmation
const auth = getAuth();

const handleLogoutClick = () => {
  setShowLogoutConfirm(true);
  toggleDrawer(false);
};

// Confirm logout
const confirmLogout = async () => {
  try {
    await signOut(auth);
    alert('You have been logged out.');
    // Redirect to login page if needed
    // window.location.href = '/login';
  } catch (error) {
    console.error('Error logging out:', error);
    alert('Failed to log out. Please try again.');
  } finally {
    setShowLogoutConfirm(false);
  }
};

// Cancel logout
const cancelLogout = () => {
  setShowLogoutConfirm(false);
};

const menuOptions = [
    { text: "My Profile" , onTap: setActiveScreenModal},
    // { text: "Settings" },
    // { text: "Messages" },
    { text: "Sign Out" , onTap: handleLogoutClick}
  ];
   return (
      <div>
      <ScreenModal
            key={'leftDrawerModal'}
                open = {isScreentModalOpen}
                handleClose={handleCloseScreenModal}
            >
        {activeModal}
      </ScreenModal>

      <ScreenModal 
        key={'logOut'}
          open = {showLogoutConfirm}
          handleClose={cancelLogout}
          maxWidth= '300px'
          maxHeight= 'auto'
          height='150px'
          // handleClose={handleCloseScreenModal}
        >
          <div className="logout-confirmation">
              <p>Are you sure you want to log out?</p>
              
              <div style={{width: '100%',}}>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* Main Title */}

                    <button className="yes-button" onClick={confirmLogout}>Yes</button>
                    <button className="no-button" onClick={cancelLogout}>No</button>                    
                  </Box>
                  

                </div>
              
            </div>
        </ScreenModal>

        {/* The Drawer */}
        <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        
        <Box
          sx={{
            width: 250,
            padding: '10px',
            backgroundColor: '#f5f5f5',
            height: '100%',
          }}
          role="presentation"
        //   onClick={toggleDrawer(false)} // Close the drawer when an item is clicked
          onKeyDown={toggleDrawer(false)} // Close when user presses a key
        >
          {state.user ? (<List>
            {menuOptions.map((option, index) => (
              <ListItem 
              
              button={'true'} key={index}
              style={{cursor:'pointer'}}
              >
                
                <ListItemText 
                onClick={() => option.onTap()}
                 primary={option.text} />
              </ListItem>
            ))}
          </List>) : (
            <ListItem 
            style={{cursor:'pointer'}}
            button={'true'} key={'login'}
            
            >
              <ListItemText  
              onClick={() => setActiveScreenModal()}
               primary='Sing Up' />
            </ListItem>
          )}
        </Box>
      </Drawer>
        </div>
      
    );
  };

  export default LeftDrawer;