import React, { useContext, useState } from 'react';
import {Divider, Drawer, Box, Typography, Button } from '@mui/material';
import { AppContext } from '../provider/AppContext';
import { StoreProdObj } from '../widgets/storeProductO';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import CheckoutScreen from './checkout';
import AddNewLocation from './addLocation';
import { red } from '@mui/material/colors';
 

export const CartSliderScreen = ({ isOpen, toggleDrawer , openChat }) => {
  const { state,  } = useContext(AppContext);  // Access global state
  const [isProductModalOpen, setProductModalOpen] = useState(false);
  

  
  // const [checkoutVisible, setCheckoutVisible] = useState(false);

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false); // To control modal visibility

  // Function to open the modal
  const openAddressModal = () => {
    // toggleDrawer(false);
    setIsAddressModalOpen(true);
  };

  // Function to close the modal
  const closeAdressModal = () => {
    setIsAddressModalOpen(false);
  };

  const handleCloseCart =() => {
    toggleDrawer(false);
    closeAdressModal();
  }

  const Expanded = ({ children, style = {} }) => {
    return (
      <div style={{ flexGrow: 1, ...style }}>
        {children}
      </div>
    );
  };

  return (
    <Box key={'cartSlider'} 
    
    >
      <Drawer anchor="right" 
      open={isOpen} 
      onClose={toggleDrawer(false)} >
        {isAddressModalOpen && 
        <AddNewLocation closeModal={closeAdressModal} isOpen={isAddressModalOpen} />
        }
       <Box
         sx={{
           flexGrow: 1,
           width: "93%",
           maxWidth: '400px',
           padding: 2,
           backgroundColor: '#f5f5f5',
           height: '100%',
         }}
       >
         
         {/* Location */}
         <Box 
        //  fullWidth 
          sx={{ display: 'flex',
            flexGrow: 1,
          alignItems: 'center',
          
          backgroundColor: '#040230',
          padding: '20px', 
          marginTop: -2,
         marginRight: -2,
         marginLeft: -2, 
         marginBottom: '10px',
        cursor:'pointer'
        }} 
        // onClick={toggleDrawer(false)}
        onClick ={openAddressModal}
         
         >
           <LocationOnIcon sx={{ color: 'white', marginRight: '0px', marginTop: '0px' }} />
           <Expanded>
           <Typography  
           style={{color: 'white'}} 
           variant="body2">
            {state.currentAddress}
            </Typography>
           </Expanded>
           
           {/* Close */}
           <Button 
           onClick={(event) => {
            event.stopPropagation(); // Prevent the click event from bubbling up
            
          }}
           >
           <Button  style={{zIndex:1100}}
            onClick={toggleDrawer(false)} 
            >
           <CloseIcon sx={{ color: 'white', marginRight: '0px', marginTop: '0px' }} />
           
         </Button>
           </Button>
         
         </Box>

         
         
         {/* Cart Items */}
         {state?.needLst?.storeProductsOL?.length === 0 ? (
           <Typography variant="body1" gutterBottom>
             Your cart is currently empty.
           </Typography>
           
         ) : (
 
           <Box>
             {(state.needLst?.storeProductsOL??[]).map((storeProductsOb, indexS) => (
               
               <Box key={indexS}>
                <StoreProdObj 
                  // setCheckoutVisible= {() => {handleCheckoutOpen(storeProductsOb.storeId)}}
                  closeCart={toggleDrawer}
                  openChat = {openChat}
                  key={storeProductsOb.storeId +"storeProd"} storeProductsO={storeProductsOb}/>

                  {/* <Drawer key={storeProductsOb.storeId+"drawer"} anchor="right" open={checkoutVisible} onClose={handleCheckoutClose}>
                    <Box sx={{
                        width: 400,
                        padding: 2,
                        backgroundColor: '#f5f5f5',
                        height: '100%',
                      }} >
                      <CheckoutScreen key={storeProductsOb.storeId+"checkout"} storeProductO= {storeProductsOb}  onClose={handleCheckoutClose} />
                    </Box>
                  </Drawer> */}
                
               </Box>
             ))}
           </Box>
           
         )}
         
         
         <Typography variant="body1" gutterBottom 
         style={{
          lineHeight: 1,
          color:'transparent',
        
        }}
         >
         _________________________________________________________
           </Typography>
       </Box>
     </Drawer>
    </Box>
    
  );
};

