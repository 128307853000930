import React, { useState, useEffect , useContext } from 'react';
import { auth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from '../firebase'; // Firebase functions
import { TextField, Button, Box, Typography, InputAdornment,
  MenuItem, Select, FormControl, InputLabel, CircularProgress,
  Avatar
 } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { AppContext } from '../provider/AppContext'; // Import the context
import axios from 'axios';
import Profile from './profile';
import CompleteProfile from './completeprofile..js';

const LoginScreen = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullPhone, setFullPhoneNumber] = useState('');
  
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [message, setMessage] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [loading, setLoading] = useState(false);


  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth,
        'recaptcha-container',  // This matches your div where recaptcha will be loaded
        {
          size: 'invisible',  // You can also set it to 'normal'
          callback: (response) => {
            // reCAPTCHA solved, allow user to submit the phone number
            console.log("Recaptcha solved!");
          },
        },
        auth // Firebase auth object
      );
    }
  };
  

  // Function to send OTP
  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (phoneNumber === '') {
      setMessage('Please enter a valid phone number');
      return;
    }

    let cleanPhoneNumber = phoneNumber;

    // Ensure the phone number starts with the country code
    const hasCountryCode = cleanPhoneNumber.startsWith(phoneCode);  // Assuming phoneCode is available
  
    // Remove any leading zero if the phone number starts with "0"
    cleanPhoneNumber = cleanPhoneNumber.replace(/^0+/, '');
  
    // If the country code is not present, prepend it
    if (!hasCountryCode) {
      cleanPhoneNumber = phoneCode + cleanPhoneNumber;
    }
  
    // Remove duplicate country code if added twice accidentally
    if (cleanPhoneNumber.startsWith(phoneCode + phoneCode)) {
      cleanPhoneNumber = cleanPhoneNumber.replace(phoneCode + phoneCode, phoneCode);
    }

    setLoading(true);
    setupRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    try {
      setFullPhoneNumber(cleanPhoneNumber)
      
      const confirmationResult = await signInWithPhoneNumber(auth, cleanPhoneNumber, appVerifier);
      
      setVerificationId(confirmationResult.verificationId);
      
      setShowOtpInput(true);
      
      setMessage('OTP sent successfully!');
    } catch (error) {
      setMessage(`Error : ${error.message}`);
    } finally {
        setLoading(false);
    }
  };


  const handleVerifyOtp = async (e) => {
    e.preventDefault();
  
    if (otp === '' ) {
      setMessage('Please enter the OTP');
      return;
    }

    if (otp.length !== 6 ) {
      setMessage('Wrong OTP');
      return;
    }
  
    try {
      // Use PhoneAuthProvider.credential from Firebase's Auth
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      
      // Sign in with the credential
      const result = await signInWithCredential(auth, credential);
      
      setMessage(`Phone number verified! Welcome, ${result.user.phoneNumber}`);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };


  const [countries, setCountries] = useState([]);
  const [selectedCountryN, setSelectedCountryN] = useState('');
  const [phoneCode, setPhoneCode] = useState("");
  // const [loading, setLoading] = useState(true);

  // Fetch countries from REST API

  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      const countryData = response.data.map((country) => ({
        code: country.cca2,
        name: country.name.common,
        flag: country.flag,
        logo: country.flags.png,
        phoneCode:
          (country.idd?.root || '') + (country.idd?.suffixes ? country.idd.suffixes[0] : ''),
      }));
      setCountries(countryData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };

  // Function to detect user's country using an external API (geolocation)
  const detectUserCountry = async () => {
    try {
      // Use a service like ipinfo.io or geoplugin.net
      const response = await axios.get('https://ipinfo.io?token=YOUR_TOKEN');  // Replace with your token
      const userCountryCode = response.data.country; // The country code from IP-based detection
      const detectedCountry = countries.find((country) => country.code === userCountryCode);
      if (detectedCountry) {
        console.log('we have detected the country')
        setSelectedCountryN(detectedCountry.code);  // Set default country
        setPhoneCode(detectedCountry.phoneCode);
        // setCountryName(detectedCountry.name);
      } else {
        console.log('country not found')
      }
    } catch (error) {
      const detectedCountry = countries.find((country) => country.phoneCode === "+20");
      if (detectedCountry) {
        console.log('we have detected the country')
        setSelectedCountryN(detectedCountry.code);  // Set default country
        setPhoneCode(detectedCountry.phoneCode);
        // setCountryName(detectedCountry.name);
      } 
      console.error('Error detecting user country:', error);
    }
  };

  useEffect(() => {

    fetchCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      const detectedCountry = countries.find((country) => country.phoneCode === "+20");
      if (detectedCountry) {
        console.log('we have detected the country')
        setSelectedCountryN(detectedCountry.code);  // Set default country
        setPhoneCode(detectedCountry.phoneCode);
        // setCountryName(detectedCountry.name);
      } 
      //  skip this for now 
      // detectUserCountry();
    }
  }, [countries]);
  // const handleCountryChange = (event) => {
  //   setSelectedCountryN(event.target.value);
  // };
  const handleCountryChange = (event) => {
    const selectedCountryCode = event.target.value;
    const selectedCountry = countries.find(
      (country) => country.code === selectedCountryCode
    );
    
    // Update states with selected country details
    setSelectedCountryN(selectedCountry.code);
    setPhoneCode(selectedCountry.phoneCode);
    // setCountryName(selectedCountry.name);
  };

  // if (loading) {
  //   return <CircularProgress />;
  // }

  const { state } = useContext(AppContext); 
  if (state.user) {
    if(state.userModel?.id) {
      return (
        <Profile/>
      );
    } else {
      return (
        <CompleteProfile/>
      );
      
    }
  }
  return (
    <div>
      {/* Phone Number Input */}
      {!showOtpInput && (
        <Box textAlign="center" padding={2}>
        <Typography variant="h4" margin={10} gutterBottom>Sign up with NasLst</Typography>
        
        {/* Country Selector */}
        
  
        <FormControl fullWidth >
        <InputLabel  id="country-select-label">Select Country</InputLabel>
        <Select 
          labelId="country-select-label"
          value={selectedCountryN}
          onChange={handleCountryChange}
        >
          {countries.map((country) => (
            <MenuItem key={country.code} 
            value={country.code}>
              {country.name} ({country.phoneCode})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
        {/* Country Selector */}
        {/* {state?.userModel?.name??"N/A"}
        {state?.userModel?.phone??"N/A"}
        {state?.needLst?.storeProductsOL?.length??'no'}
        {state?.needLst?.id??'no'} */}
        <TextField
          fullWidth
          placeholder=   "Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          InputProps ={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mt: 2, mb: 2 }}
        />
        <div>
        {fullPhone}
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendOtp}
          fullWidth
          disabled={loading}
          sx={{ backgroundColor: '#f7c441' }}
        >
          {loading ? 'Sending...' : 'Verify now'}
        </Button>
  
        <div id="recaptcha-container"></div>
        {message && <Typography color="error">{message}</Typography>}
      </Box>
      )}

      {/* OTP Input */}
      {showOtpInput && (
        <Box textAlign="center" padding={2}>
        <Typography variant="h5" margin={10} gutterBottom>OTP Verification</Typography>
        <Typography variant="body2" gutterBottom>We sent your code to {fullPhone}</Typography>
        <Typography variant="body2" color="textSecondary">This code will expire in 2 minutes</Typography>
  
        <TextField
          fullWidth
          placeholder="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          sx={{ mt: 2, mb: 2 }}
        />
  
        <Button
          variant="contained"
          color="black"
          onClick={handleVerifyOtp}
          fullWidth
          disabled={loading}
          sx={{ backgroundColor: '#FFD31A' }}
        >
          {loading ? 'Verifying...' : 'Continue'}
        </Button>
  
        {message && <Typography padding={2} color="primary">{message}</Typography>}
      </Box>
      )}

      {/* Show message */}
      {message && <p>{}</p>}
    </div>
  );
};

export default LoginScreen;
