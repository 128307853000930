import { Timestamp, addDoc, collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { getPaymentFromId } from "./constants.js";
import { firestore } from '../firebase.js';



const invoiceCollection = 'invoices'
// / Function to add value to Firestore (assuming you're using Firebase here)
const addValueRequest = async (paymentData) => {
  const docRef = await addDoc(collection(firestore, invoiceCollection), paymentData);
  return docRef;
};

export const generateFawaterkSessionForOnlineOrder = async (
  paymentId, amount, userModel, userCollection, invoiceType,
  amountDestination, cartL, customPayLoadL, customer, selectedPayment, targetUrl
) => {
  let fawaterkurl;
  const payD = {
    payment_method_id: paymentId,
    cartTotal: (amount / 100).toString().trim(),
    currency: 'EGP',
    customer: customer,
    cartItems: cartL,
    customPayLoadL: customPayLoadL,
    paymentMethod: getPaymentFromId(paymentId),  // Assuming you have this function
    paymentLogo: selectedPayment?.logo??"",
    redirectionUrls: {
      successUrl: `${targetUrl}?req=payment&rid=success`,
      failUrl: `${targetUrl}?req=payment&rid=fail`,
      pendingUrl: `${targetUrl}?req=payment&rid=pending`,
    },
    timestamp: Timestamp.now(),  // Adjusted to JS
    invoiceType: invoiceType,
    amount: amount,
    userId: userModel?.id || 'unknown',
    userName: userModel?.name || 'unknown',
    country: userModel?.addressO?.countryT?.en || '',
    gateWayResponse: 'waiting',
    specsN: userModel?.specsN || '',
    specsL: userModel?.specsL || [],
    gatewayId: selectedPayment?.gatewayId,
  };

  const docRef = await addValueRequest(payD); // Add value to Firestore

  // let paymentData;

  // Setting up a listener for Firestore document updates
  // const unsubscribe = onSnapshot(doc(firestore, invoiceCollection, docRef.id), async (docSnapshot) => {
  //   if (docSnapshot.exists()) {
  //     paymentData = docSnapshot.data();
  //     fawaterkurl = '';

  //     if (paymentData.gateWayResponse !== 'waiting') {
  //       if (paymentData.paymentLink) {
  //         fawaterkurl = paymentData.paymentLink;
  //         unsubscribe(); // Unsubscribe from the listener once done
  //       } else if (paymentData.paymentReference) {
  //         fawaterkurl = paymentData.paymentReference;
  //         unsubscribe(); // Unsubscribe from the listener once done
  //       }
  //     }
  //   }
  // });

  // Timeout to complete after 20 seconds
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  let paymentData;
    // Return a Promise that will resolve when the Firestore listener gets a response
  const paymentDataPromis = await new Promise((resolve, reject) => {
    // Setting up a listener for Firestore document updates
    const unsubscribe = onSnapshot(doc(firestore, invoiceCollection, docRef.id), (docSnapshot) => {
      if (docSnapshot.exists()) {
        paymentData = docSnapshot.data();

        console.log("Document snapshot data:", paymentData); // Log full snapshot data

        // Only resolve when the gateWayResponse is no longer 'waiting'
        if (paymentData.gateWayResponse !== 'waiting') {
          unsubscribe(); // Unsubscribe from the listener once done

          if (paymentData.paymentLink) {
            console.log("Payment link found:", paymentData.paymentLink); // Log payment link if found
            resolve(paymentData);
          } else if (paymentData.paymentReference) {
            console.log("Payment reference found:", paymentData.paymentReference); // Log payment reference if found
            resolve(paymentData);
          } 
        }
      } else {
        console.error("Document snapshot does not exist.");
        reject(new Error('Document does not exist.'));
      }
    }, (error) => {
      unsubscribe(); // Unsubscribe in case of an error
      reject(error); // Reject the promise if there's an error
    });
  });

  // const thePaymentData = await getDoc(doc(firestore,'invoices', docRef.id));

  console.log("paymentData");
  console.log(paymentData);
  
  return paymentData;
};



// export const generateFawaterksessionForOnlineOrder = async (
//      paymentId,  amount,
//      userModel, userCollection,
//     invoiceType, amountDestination,
//      cartL,  _customPayLoadL,
//       _cst, selectedPayment,
//     targetUrl ) => {
//   //print("generateFawaterksessionForOnlineOrder");
//   let fawaterkurl;
//   const  payD = {
//       paymentMethodId: paymentId,
//       cartTotal: (amount/100).toString(),
//       currency: 'EGP',
//       customer: _cst,
//       cartItems: cartL,
//       customPayLoadL: _customPayLoadL,
//       paymentMethod : getPaymentFromId(paymentId),
//       redirectionUrls: {
//           successUrl: targetUrl + "?${WEBREQ.REQ}=${WEBREQ.PAYMENT}&${WEBREQ.RID}=success",
//           failUrl: targetUrl + "?${WEBREQ.REQ}=${WEBREQ.PAYMENT}&${WEBREQ.RID}=fail",
//           pendingUrl: targetUrl + "?${WEBREQ.REQ}=${WEBREQ.PAYMENT}&${WEBREQ.RID}=pending"
//       },
//       timestamp: Timestamp.now(),
//       invoiceType: invoiceType,
//       amount: amount,
//       // toRef: amountDestination,
//       // fromRef: 'accountLedger/egypt-bank-fawaterak',
//       userId: userModel?.id??"unknown",
//       userName: (userModel?.name??"unknown"),
//       country: (userModel?.addressO?.countryT??{})['en']??"",
//       gateWayResponse: "waiting",
//       specsN: userModel?.specsN??"",
//       specsL: userModel?.specsL??[],
//       gatewayId: selectedPayment?.gatewayId
//   };

//   const completer = Completer<void>();

//   final docRef = await addValueRequest(payD.toJson());

//   PaymentDataModel? theP;
//   final subscription = await _firestore.collection(collection).doc(docRef.id).snapshots().listen((event) async {
//     if(event.data() != null) {
//       theP = PaymentDataModel.fromSnapshot(event);
//       fawaterkurl = "";
//       //print("we are waiting the cloud response");
//       if (theP?.gateWayResponse != "waiting") {
//         if ((theP?.paymentLink??"").isNotEmpty == true) {
//           //print("theP.paymentLink??"").isNotEmpty");
//           fawaterkurl = theP?.paymentLink;
//           if (completer?.isCompleted == false) {
//             completer?.complete();
//           }
//         } else if((theP?.paymentReference??"").isNotEmpty == true) {
//           //print("theP.paymentReference??"").isNotEmpty");
//           fawaterkurl = theP?.paymentReference;
//           if (completer?.isCompleted == false) {
//             completer?.complete();
//           }
//         } else {
//           if (completer?.isCompleted == false) {
//             completer?.complete();
//           }
//         }
//       }
//     }
//   });
//   Future.delayed(Duration(seconds: 20), () {
//     if (completer?.isCompleted == false) {
//       completer?.complete();
//     }
//   });
//   //print("Waiting for completer");
//   await completer.future;
//   //print("Now completed");
//   subscription?.cancel();
//   return theP;

// }