// src/Profile.js
import React, { useState, useEffect , useContext } from 'react';
import '../Profile.css'; // We'll create this CSS file for styling
import { FaPen } from 'react-icons/fa'; // Font Awesome for the edit icon
import { AppContext } from '../provider/AppContext'; // Import the context
import { storage, firestore as firestore } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';



const Profile = () => {
    const { state, } = useContext(AppContext); 
    const [profileImage, setProfileImage] = useState(
        state.userModel?.pic??
        'https://via.placeholder.com/150');

    
    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //       const reader = new FileReader();
    //       reader.onload = () => {
    //         setProfileImage(reader.result);
    //       };
    //       reader.readAsDataURL(file);
    //     }
    //   };

  const [uploading, setUploading] = useState(false);

  // Handle file input change
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = () => {
        setProfileImage(reader.result);
        };
        reader.readAsDataURL(file);

      setUploading(true);

      // Create a storage reference
      const storageRef = ref(storage, `customers/${file.name}`);

      try {
        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        // Update the profileImage state
        setProfileImage(downloadURL);

        // Update Firestore (Replace 'userId' with actual user's ID)
        const userDocRef = doc(firestore, 'customers', state.user?.uid); // Replace 'userId' with the actual user ID
        await updateDoc(userDocRef, {
          pic: downloadURL,
        });

        alert('Profile image updated successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload image. Please try again.');
      } finally {
        setUploading(false);
      }
    }
  };
    
      // Handle image click to open file input
      const handleImageClick = () => {
        document.getElementById('fileInput').click();
      };

      
    return (
    <div className="profile-container">
      {/* Close Button */}
      {/* <button className="close-button">&times;</button> */}

      {/* Profile Header */}
      <h2 className="profile-title">Profile</h2>

      {/* Profile Image */}
      <div className="profile-image-container" 
      >
        <img
          src={profileImage}
          alt="Profile"
          className="profile-image"
          onClick={handleImageClick}
        />
    {/* Hidden file input */}
    <input
          id="fileInput"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </div>
           

    {/* Uploading Indicator */}
    {uploading && <p>Uploading...</p>}

      {/* Profile Info */}
      <div className="profile-info">
        <h3 className="profile-name">{state.userModel?.name??""}</h3>
        <p className="profile-contact">{state.userModel?.phone??""}</p>
        <p className="profile-email">{state.userModel?.email??""}</p>
      </div>

      {/* Edit Icon */}
      {/* <button className="edit-button">
        <FaPen />
      </button> */}
    </div>
  );
};

export default Profile;
