import React, { createContext, useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Timestamp, collection, doc, getDoc, getDocs, setDoc,
  query,  where,     orderBy, limit

 } from 'firebase/firestore';
import { firestore, geofirestore, GeoPoint, ngeohash } from '../firebase';  // Ensure Firebase config is imported
import { v1 as uuidv1 } from 'uuid';
import PackageModel from '../model/package'
import { REACT_APP_GOOGLE_MAPS_KEY, ReadAddress } from '../constants/constants';

import { GeoFirestore, GeoCollectionReference } from "geofirestore";


// Create the context
export const AppContext = createContext();

// Create the provider component
export const AppProvider = ({ children }) => {
  console.log('AppProviderInit');
  const [state, setState] = useState({
    user: null,       // Example user data
    userModel: null,
    needLst: {},         // Example cart for an e-commerce app
    theme: 'light',
    selectedOrderType: "Deliver",   // Example theme state
    selectedDineInTable:null,
    currentAddressO:{},
    recentAddressL:[],
    currentAddress:"",
    currentGeoPoint:null,
  });
  const [activeOrderId , setActiveOrderId] = useState(null);
  const [needLst , setOldNeedLst] = useState({})
  // useRef to keep the current needLst
  const needLstRef = useRef(state.needLst);

  // Use this to update the needLst and keep the ref in sync
  const setNeedLst = (newNeedLst) => {
    needLstRef.current = newNeedLst;
    setState((prevState) => ({
      ...prevState,
      needLst: newNeedLst,
    }));
    setOldNeedLst(newNeedLst);
  };

    
  let [recentAddressL, setRecentAddressL] = useState([]);

  const [checkoutVisible, setCheckoutVisible] = useState(false);
  const [checkOutStoreProduct, setStoreCheckOut] = useState(false);
  const auth = getAuth();  // Initialize Firebase auth

  const handleActiveOrderId = (orderK) => {
    if (!orderK) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    setActiveOrderId(orderK);
  }

  // Auto-detect the current active user and fetch data
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, fetch user data from Firestore
        const userDoc = doc(firestore, 'customers', user.uid);
        const userData = await getDoc(userDoc);

        const userCartDoc = doc(firestore, 'needLst', user.uid);
        const userCartData = await getDoc(userCartDoc);

        if (userData.exists()) {
          // Getting customer address
          const userModel = userData.data()
          let theCurrentAddress = state.currentAddress;
          let theCurrentGeopoint = state.currentGeoPoint
          if ((userModel.addressO??null) != null) {
            console.log('we are inside user position' , userModel?.position?.geopoint?.longitude)
            if ( userModel?.position?.geopoint?.latitude != userModel?.addressO?.position?.geopoint?.latitude || 
                 userModel?.position?.geopoint?.longitude != userModel?.addressO?.position?.geopoint?.longitude ) {
              if (userModel?.position?.geopoint?.latitude) {
                console.log('updating user position' , userModel?.position?.geopoint?.longitude)
                theCurrentGeopoint = userModel?.position?.geopoint;
                updateUserAddress({lat:userModel?.position?.geopoint?.latitude, lng:userModel?.position?.geopoint?.longitude, updateUser:false});
              }
              
            } else {
              theCurrentGeopoint = userModel?.addressO?.position?.geopoint;
              theCurrentAddress = ReadAddress(userModel?.addressO)
              setState((prevState) => ({
                ...prevState,
                currentGeoPoint: theCurrentGeopoint,
                currentAddress:  theCurrentAddress,
                currentAddressO: userModel?.addressO
              }));
            }
          } else {
            if (userModel?.position?.geopoint?.lat) {
              theCurrentGeopoint = userModel?.position?.geopoint;
              updateUserAddress({lat:userModel?.position?.geopoint?.lat, lng:userModel?.position?.geopoint?.lng, updateUser:false});

            }
          }

        // Getting the current cart
        let needLstModel = { ...needLstRef.current }; // Use ref to retain current state
        let userCart = userCartData.data() || {};
        
        console.log('mergging 1');
        // Check and update storeProductsOL
        if (needLstModel?.storeProductsOL?.length > 0) {
          console.log('mergging 2');
          // If userCart already contains storeProductsOL
          if (userCart?.storeProductsOL?.length > 0) {
            console.log('mergging 3');
            // Compare and update storeProductsOL based on storeId
            const updatedStoreProductsOL = [...userCart.storeProductsOL];

            // Loop through needLstModel's storeProductsOL to find and update matching storeIds
            needLstModel.storeProductsOL.forEach((needLstItem) => {
              console.log('mergging 4');  
              const index = updatedStoreProductsOL.findIndex(
                (cartItem) => cartItem.storeId === needLstItem.storeId
              );

              if (index !== -1) {
                console.log('mergging 5');
                // If storeId exists in the userCart, replace it
                updatedStoreProductsOL[index] = needLstItem;
              } else {
                console.log('mergging 6');
                // If storeId does not exist, add the new item
                updatedStoreProductsOL.unshift(needLstItem);
              }
            });

            // Update the userCart's storeProductsOL with the modified list
            userCart.storeProductsOL = updatedStoreProductsOL;
          } else {
            // If userCart does not contain storeProductsOL, add it directly
            userCart.storeProductsOL = [...needLstModel.storeProductsOL];
          }
        }
          
          setState((prevState) => ({
            ...prevState,
            user: user,
            userModel: userData.data(),
            needLst: {...userCart},
          }));
          setNeedLst({...userCart});

          const q = query(collection(firestore, "customers", user.uid, 'addressBook')
              , orderBy("timestamp", "desc")
              , limit(20)
            );
  
          const querySnapshot = await  getDocs(q);
          const listData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          if (listData.length> 0) {
            console.log('has address' + listData.length.toString());
            setRecentAddressL([...listData]);
            setState((prevState) => ({
              ...prevState,
              recentAddressL:listData
            }));
          } else {
            console.log('has no address');
            setRecentAddressL([]);
          }
          
        } else {
          console.log('No such user data found in Firestore!');
        }
      } else {
        // User is signed out, reset the state
        // console.log('clear user info');
        setState((prevState) => ({
          ...prevState,
          userModel:null,
          user: null,
          needLst: {},
          theme: 'light',
        }));
        setRecentAddressL([]);
        setNeedLst({})
      }
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [auth]);

  // Function to update user data in the state
  const updateUser = (newUser) => {
    setState((prevState) => ({
      ...prevState,
      user: newUser,
    }));
  };

  // // Function to update the cart and write it to Firestore
  // const updateCart = async (storeId, orderType, newCartItem) => {
  //   const updatedCart = [...state.needLst, newCartItem];

  //   // 
  //   const storeProductsO = state.needLst.storeProductsOL[0];
  //   // needLst.storeProductsOL = 

  //   // Update state
  //   setState((prevState) => ({
  //     ...prevState,
  //     needLst: updatedCart,
  //   }));
  //   setNeedLst(updatedCart)

  //   // Write the updated cart to Firestore if a user is logged in
  //   if (state.user) {
  //     const userDocRef = doc(firestore, 'users', state.user.uid);
  //     await setDoc(userDocRef, { cart: updatedCart }, { merge: true });
  //   }
  // };

  // Function to get both GeoPoint and GeoHash
const getGeoPointAndGeoHash = (lat, lng) => {
  // Create GeoPoint using Firestore's GeoPoint object
  const geopoint = new GeoPoint(lat, lng);

  // Generate GeoHash using ngeohash
  const geohash = ngeohash.encode(lat, lng);

  return { geopoint, geohash };
};

// Function to get a readable address from lat/lng using Google Geocoding API
const getReadableAddress = async (lat, lng) => {
  const apiKey = REACT_APP_GOOGLE_MAPS_KEY; // Replace with your API key

  // Build the request URL for reverse geocoding
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  try {
    // Make a request to the Geocoding API
    const response = await fetch(url);
    const data = await response.json();

    if (data.status === "OK") {
      // Extract the formatted address from the response
      const readableAddress = data.results[0].formatted_address;
      console.log(data.results[0])
      const addressComponents = data.results[0].address_components;
      // Extract desired information from the address components
      let area = getComponent(addressComponents, "sublocality"); // Area name
      if (!area){
        area = getComponent(addressComponents, "administrative_area_level_2"); // Area name
      }
      
      let city = getComponent(addressComponents, "locality"); // City name
      if (!city){
        city = getComponent(addressComponents, "administrative_area_level_1"); // Area name
      }

      let street = getComponent(addressComponents, "route"); // Street name

      let district = area;
      if (!district){
        district = getComponent(addressComponents, "administrative_area_level_3"); // Street name
      }
      const country = getComponent(addressComponents, "country"); // Country name


      console.log('country', country, 'city', city, 'area'  , area, 'district', district , 'streat' , street);

      return {readableAddress, district,street, area, city, country};
    } else {
      throw new Error("Geocoding API error: " + data.status);
    }
  } catch (error) {
    console.error("Error fetching address: ", error);
    return null;
  }
};

// Helper function to extract address components by type
const getComponent = (components, type) => {
  const component = components.find((comp) => comp.types.includes(type));
  return component ? component.long_name : null;
};

const cleanFormattedAddress = (address) => {
  // Regular expression to match Plus Codes (e.g., H7M8+65J)
  const plusCodeRegex = /\b[A-Z0-9]{2,}\+[A-Z0-9]{2,}\b/g;

  // Remove the Plus Code from the formatted address
  const cleanAddress = address
    .replace(plusCodeRegex, "")    // Remove Plus Code
    .replace(/\s+/g, " ")          // Collapse multiple spaces into one
    .trim();                       // Remove leading and trailing spaces

  return cleanAddress;
};

  const updateUserAddress = async ({newAddress, addressO, lat, lng , updateUser = true}) => {
    if (addressO) {
      if (state.userModel) {
        let theUserModel = state.userModel
        theUserModel.addressO = addressO;
        theUserModel.position = addressO.position;
        let theCurrentAddress = ReadAddress(addressO);
        let theCurrentGeopoint = addressO?.position?.geopoint;
        setState((prevState) => ({
          ...prevState,
          userModel: theUserModel,
          currentAddress: theCurrentAddress,
          currentGeoPoint:theCurrentGeopoint,
          currentAddressO: addressO
        }));
        if (state.user) {
          const userDocRef = doc(firestore, 'customers', state.user.uid);
          await setDoc(userDocRef, {
            addressO:addressO,
            position:addressO?.position??{}
          }, { merge: true });
          if (newAddress) {
            
            const addressBookRef = doc(firestore, "customers", state.user.uid, 'addressBook', addressO.uuid);
            await setDoc(addressBookRef,  addressO , { merge: true });
            const listData = [addressO, ...recentAddressL ];
            setRecentAddressL([...listData]);
            setState((prevState) => ({
              ...prevState,
              recentAddressL:listData
            }));
          }
        }
      }
    } else if (lat && lng) {
        let currentAddressO = {};
        console.log('got some lat lng');
        const geopoint = new GeoPoint(lat,lng);
        console.log("geopoint" ,geopoint);
        const position = getGeoPointAndGeoHash(lat, lng);
        console.log("geoHash" ,position.geohash);
        const component = await getReadableAddress(lat,lng);
        const theCurrentAddress = cleanFormattedAddress(component.readableAddress);
        let theCurrentGeopoint = geopoint
        console.log("currentAddress" , theCurrentAddress);
        currentAddressO.position = position;
        currentAddressO.district = component.district;
        currentAddressO.street = component.street;
        
        currentAddressO.countryT = {en:component.country, ar:component.country};
        currentAddressO.cityT = {en:component.city, ar:component.city};
        currentAddressO.areaT = {en:component.area, ar:component.area};

        setState((prevState) => ({
          ...prevState,
          currentAddressO: currentAddressO,
          currentGeoPoint:theCurrentGeopoint,
          currentAddress: theCurrentAddress,
        }));

        if (state.user && updateUser) {
          let theUserModel = state.userModel;
          theUserModel.position = position;
          setState((prevState) => ({
            ...prevState,
            userModel: theUserModel,
          }));
          const userDocRef = doc(firestore, 'customers', state.user.uid);
          await setDoc(userDocRef, {
            position: position
          }, { merge: true });
        }
    }
  }
  

  let hasNewLocation = () => (state.currentGeoPoint?.latitude != state.userModel?.addressO?.position?.geopoint?.latitude &&
    state.currentGeoPoint?.longitude != state.userModel?.addressO?.position?.geopoint?.longitude);

  const updateStoreProductOQuantity = async (storesModel, theProducts, { quantity, note, merge }) => {
    if ((storesModel?.id ?? "") !== "") {
      try {
      quantity = quantity ?? 1;
      note = note ?? "";
      merge = merge ?? true;
      
      const products = theProducts;

      let needLstModel = state.needLst??{};
      let selectedOrderType = state.selectedOrderType??"Deliver";
      let selectedDineInTable = null;
      let newSelectType = "Deliver";
  
      if (storesModel?.stOrderType?.length > 0) {
        selectedOrderType = storesModel?.stOrderType ?? "Deliver";
        newSelectType = selectedOrderType;
        if (newSelectType.toLowerCase().includes('dine in')) {
          selectedDineInTable = storesModel?.stTable ?? 1;
        }
      }
  
      (storesModel?.onlineOptionsL ?? []).forEach((element) => {
        const cleanedElement = (element??"").toString().toLowerCase();
        const cleanedOrderType = selectedOrderType.replaceAll("_", " ").toLowerCase();
  
        if (cleanedElement.includes(cleanedOrderType)) {
          newSelectType = element??"";
        }
      });
  
      if ((selectedOrderType ?? "") === '') {
        selectedOrderType = storesModel?.onlineOptionsL?.[0] ?? "Deliver";
        newSelectType = selectedOrderType??"";
      }
  
      let table = null;
      if (newSelectType.toLowerCase().includes('dine in')) {
        table = selectedDineInTable;
      }
  
      if (storesModel?.id && needLstModel && products) {
        let stL = needLstModel.storeProductsOL ?? [];
        let foundStore = false;
  
        for (const st of stL) {
          if (st?.storeId === storesModel?.id) {
            foundStore = true;
  
            let foundProduct = false;
            let product;
            if (merge) {
               product = st.productsOL?.find((element) => element.uuid === products.uuid);
              } 
  
            // If the product is found, modify it
            if (product) {
              if (merge) {
                foundProduct = true;
                product.quantity = (product.quantity ?? 0) + quantity;
                
                // If the product's quantity is now 0 or less, remove it
                if (product.quantity <= 0) {
                  if (merge) {
                    st.productsOL = st.productsOL.filter((p) => p.uuid !== product.uuid);
                  } 
                }
              }
            }

            // If no product was found and merge is false, add the new product
            if (!foundProduct) {
              let p = products.toProductO();
               p = { ...p, note: note ?? "", quantity };
              if (!merge) {
              // p.uuid = (products.generateUuid()??uuidv1()) + note;
              p.uuid = uuidv1('&times');
              }
              st.productsOL.push(p);
            }
  
            // Check if this store now has no products and remove it
            if (st.productsOL.length === 0) {
              stL = stL.filter((store) => store.storeId !== st.storeId);
            }
          }
        }
  
        // If no store was found, add the store with the product
        if (!foundStore) {
          let p = products.toProductO();
          if (storesModel.activePackage == true) {
            p.isPackage = true;
          } else {
            p.isPackage = false;
          }
          p = { ...p, note: note ?? "", quantity };
          if (!merge) {
            // p.uuid = (products.generateUuid()??uuidv1()) + note;
            p.uuid = uuidv1();
          }
          let stOO = {
            storeId: storesModel?.id,
            num: (needLstModel?.storeProductsOL?.length ?? 0) + 1,
            productsOL: [p],
            orderType: newSelectType,
            table: table,
          };
          stL.push(stOO);
        }
  
        
        needLstModel.storeProductsOL = stL;
  
        setState((prevState) => ({
          ...prevState,
          needLst: needLstModel,
        }));
        setNeedLst(needLstModel)
  
        // Write the updated cart to Firestore if a user is logged in
        if (state.user) {
          const userDocRef = doc(firestore, 'needLst', state.user.uid);
          await setDoc(userDocRef, needLstModel, { merge: true });
        }
      }
    } catch {

    }
  }
  };

  const clearStoreProductO = async (storesModel, ) => {
    if ((storesModel?.id ?? "") !== "") {
      try {
      

      let needLstModel = state.needLst??{};
  
      if (storesModel?.id && needLstModel ) {
        let stL = needLstModel.storeProductsOL ?? [];
        let foundStore = false;
  
        for (const st of stL) {
          if (st?.storeId === storesModel?.id) {
            stL = stL.filter((store) => store.storeId !== st.storeId);
          }
        }
  
        needLstModel.storeProductsOL = stL;
  
        setState((prevState) => ({
          ...prevState,
          needLst: needLstModel,
        }));
        setNeedLst(needLstModel)
  
        // Write the updated cart to Firestore if a user is logged in
        if (state.user) {
          const userDocRef = doc(firestore, 'needLst', state.user.uid);
          await setDoc(userDocRef, needLstModel, { merge: true });
        }
      }
    } catch {

    }
  }
  };


  

  const setCurrentOrderType = async ( storeId, option, table)  => {
    console.log('storeId is ' , storeId , 'option', option, 'table ' , table )
    let needLstModel = needLst;
    let stL = needLstModel?.storeProductsOL??[];
    if(stL.length > 0) {
      console.log('There is founded stores in needLst')
    }
    let foundStore = false;
    for (const st of stL) {
      if (st.storeId == storeId) {

        foundStore = true;
        st.orderType = option??"Deliver";
        st.table = table??st.table;
      }
   }
   if (foundStore == true) {
    console.log('founded store')
    needLstModel.storeProductsOL = stL;
    needLstModel.timestamp = Timestamp.now()
  
    setState((prevState) => ({
      ...prevState,
      needLst: needLstModel,
      selectedOrderType: option,
      selectedDineInTable: table,
    }));
    setNeedLst(needLstModel)
  
    // Write the updated cart to Firestore if a user is logged in
    if (state.user) {
      const userDocRef = doc(firestore, 'needLst', state.user.uid);
      await setDoc(userDocRef, needLstModel, { merge: true });
    }

   } else  if(foundStore == false) {
    console.log('not found store')
   //print("Nothing found");

   setState((prevState) => ({
      ...prevState,
      selectedOrderType: option,
      selectedDineInTable: table,
    }));
    }
  }

  
  // Function to change theme
  const changeTheme = (newTheme) => {
    setState((prevState) => ({
      ...prevState,
      theme: newTheme,
    }));
  };

  // The value provided will include both the state and the updater functions
  return (
    <AppContext.Provider
      value={{
        state,
        needLst,
        recentAddressL,
        checkoutVisible,
        hasNewLocation,
        updateUser,
        updateUserAddress,
        setCurrentOrderType,
        changeTheme,
        updateStoreProductOQuantity,
        setCheckoutVisible,
        setStoreCheckOut,
        activeOrderId,
        handleActiveOrderId,
        clearStoreProductO
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
