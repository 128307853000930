import React, { useState } from 'react';
import { Button, Typography, Box, Paper, Snackbar } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react'; // Use QRCodeCanvas or QRCodeSVG as needed


function PaymentUI({gatewayResponse, invoiceDocument, onCompleted}) {

  const paymentId  = invoiceDocument?.payment_method_id??"";  // Example payment ID
  const paymentLogoUrl = invoiceDocument?.paymentLogo??"";
  const walletPhone = invoiceDocument?.mobileWalletNumber??"";
  const paymentAmount = invoiceDocument?.cartTotal??"";  // Example amount
  const qrResponse = invoiceDocument?.responseData?.data?.payment_data?.meezaQrCode??"";  // Example QR Data
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(gatewayResponse);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };


  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2}>
      <Typography variant="h6" align="center" fontWeight="bold">
        Waiting Payment
      </Typography>

      <Typography align="center">
        Order invoice created and waiting for payment, order will be auto sent to store when paid successfully
      </Typography>

      <Typography variant="h5" fontWeight="bold" align="center" mt={2} mb={2}>
        {paymentAmount} EGP
      </Typography>

      {/* Payment Logo */}
      <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <img src={paymentLogoUrl} alt="Payment Logo" width={60} height={60} />
        <Typography variant="caption">{`Payment Name`}</Typography>
      </Paper>

      <Typography>Reference Id for</Typography>

      {/* QR Code if paymentId is 4 */}
      {paymentId === 4 && (
        <Box mt={2} mb={2}>
        <QRCodeCanvas value={qrResponse} size={120} /> {/* Using QRCodeCanvas for rendering */}
      </Box>
      )}

      {/* Display additional info for specific payment IDs */}
      {paymentId === 4 && (
        <Box mt={2}>
          <Typography>Phone Number: {walletPhone}</Typography>
          <Typography>You have received a message to recharge {paymentAmount} EGP</Typography>
        </Box>
      )}

      {(paymentId === 12 || paymentId === 14) && (
        <Box mt={2}>
          <Typography>Pay this invoice, select Fawaterak, and enter the code to recharge {paymentAmount} EGP</Typography>
        </Box>
      )}

      {/* Payment Gateway Response */}
      {paymentId !== 4 && (
        <>
          <Typography variant="h6" fontWeight="bold" mt={2} mb={2}>
            {gatewayResponse}
          </Typography>

          <Button variant="contained" sx={{backgroundColor:'gray'}} onClick={handleCopy}>
            Copy
          </Button>
        </>
      )}

      {/* Snackbar for copy notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Copied"
      />

      {/* Change Payment Button */}
      <Button 
        sx={{padding:'6' , margin:'6px'}}
        variant="contained" color="secondary" 
        onClick={() => {
            if(onCompleted){
                onCompleted();
            }
        }}>
        Change Payment
      </Button>
    </Box>
  );
}

export default PaymentUI;
