import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Divider, Box,  Typography, Button, AppBar, Toolbar, TextField, IconButton,
     Checkbox , useMediaQuery,
 } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AppContext } from '../provider/AppContext'; // Import the context

import PackageModel from '../model/package';


const ProductPage = ({ storeModel, openCart, handleClose, productK , isPackages = true }) => {
  productK = PackageModel.fromJson(productK, productK?.id);
  const [product , setPackModel] =  useState(productK);

    const [quantity, setQuantity] = useState(1);
    const [size, setSize] = useState('Medium'); // Default size
    
    const [addOns, setAddOns] = useState({
      extraCheese: false,
      extraSauce: false,
    });
    
    
    const [productCopy, setProductCopy] = useState(product);
    
    const [priceOptions, setPriceOptions] = useState(
      product?.priceOptions??{});

    
    const [addOnOptionsL, setAddOnOptions] = useState(
      [...(product?.addOnOptionsL??[])]);

    // Handle price options
    const handlePriceOption = (addOnIndex) => {
      // Create a new copy of the priceOptions object (immutably)
      const updatedPriceOptions = { ...priceOptions };
    
      // Loop through all add-ons to unselect them
      updatedPriceOptions.addOns = updatedPriceOptions.addOns.map((addOn, index) => {
        return {
          ...addOn,
          selected: index === addOnIndex, // Select only the one with the matching index
        };
      });
    
      // Set the updated state
      setPriceOptions(updatedPriceOptions);
      let prod = product
      prod.priceOptions = updatedPriceOptions
      setPackModel(prod)
    };

    // handle addons
    const handleAddOnChange = (optionIndex, addOnIndex) => {
      // Create a new copy of the addOnOptions array (immutable approach)
      const updatedAddOnOptions = [...addOnOptionsL];
  
      // Update the specific add-on's selected state
      updatedAddOnOptions[optionIndex].addOns[addOnIndex].selected =
        !updatedAddOnOptions[optionIndex].addOns[addOnIndex].selected;
  
      // Set the updated state
      setAddOnOptions(updatedAddOnOptions);
    };

    // [{'nameT':{'en':'hey'}, 'uuid':'heee', 'addOns':[]}]
    const [note, setNote] = useState('');
  
    const isSmallScreen = useMediaQuery('(max-width: 800px)'); // Adjust screen size for responsiveness
  
    const { state,  updateStoreProductOQuantity } = useContext(AppContext); // Access the cart state and the function to update the cart

    
    // Handlers for quantity, size, add-ons, and note
    const handleQuantityChange = (increment) => {
      setQuantity(prev => Math.max(1, prev + increment)); // Prevent negative quantity
    };

    const handleAdd = (event) => {
      event.stopPropagation(); // Prevent card click when add button is pressed
      handleQuantityChange( 1); // Increase the quantity
      
    };
  
    const handleRemove = (event) => {
      event.stopPropagation(); // Prevent card click when remove button is pressed
      if (quantity > 0) {
        handleQuantityChange(-1);
      }
    };
  
    const handleSizeChange = (event) => {
      setSize(event.target.value);
    };
  
    const handleAddOnChange2 = (event) => {
      setAddOns({ ...addOns, [event.target.name]: event.target.checked });
    };
  
    const handleNoteChange = (event) => {
      setNote(event.target.value);
    };
  
    const handleOpenCart = () => {
        openCart()
        handleClose();
    }
    const handleAddToCart = ({item}) => {
      // Add to cart logic
      // updateCart(storeId,orderType , item);

      updateStoreProductOQuantity(storeModel, item , {quantity: quantity , note:note ,merge: false})
      console.log(`Added ${quantity} x ${product.name} to the cart with size ${size}, add-ons: ${JSON.stringify(addOns)}, note: ${note}`);
    //   handleClose(); // Close the modal after adding to cart
    };
  
    const imgLinkURL = "https://firebasestorage.googleapis.com/v0/b/naslst.appspot.com/o/";
    const img = isPackages? (product.img) : imgLinkURL +(product.img)
    const name = (product?.nameT??{})['en']


    const availableQuantity = () => {
      let av = 0;
      {(state.needLst.storeProductsOL??[]).map(element => (
        (element?.storeId == storeModel?.id)?
          (element.productsOL??[]).map(theProduct => (
            (product?.id == theProduct?.id)?
                av = av + (theProduct.quantity??0)
            : 0
          ))
        : 0
      ))}
      return av;
    }
    
    return (
        <Grid
        container
        direction={{ xs: 'auto', md: 'row' }} // Column on small screens, row on larger screens
        // direction= {{ xs: 'auto',  md: 'auto' }} // Column on small screens, row on larger screens
        sx={{
          height: '100%', // Full height for both parts
          overflow: 'auto',
          // overflowY:  'auto',  // Vertical scroll for small screens, horizontal for large
        }}
      >
        {/* Left Half: Product Image */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f0f0f0',
            height: { xs: 'auto', md: '100%' }, // Full height on larger screens
          }}
        >
            
          {img??"" != ""? (<Box
            component="img"
            src={img}
            alt={(product?.nameT??{})['en']}
            sx={{
              width: '100%',
              maxHeight: '90vh',
              objectFit: 'cover',
            }}
          />):null}
        </Grid>

        {/* Right Half: Product Details */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 3,
            overflowY: 'auto', // Independent scrolling for product details
            height: { xs: 'auto', md: '100%' }, // Full height when in row layout
          }}
        >
            
          


          {/* Product Name */}
          
          <Typography variant="h5" gutterBottom>
            {(product?.nameT??{})['en']}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            {product.description}
          </Typography>
          {/* The read AddOn Options */}
          {(priceOptions?.addOns?.length??0) > 0 ? (
            <Box key={priceOptions?.uuid??""} 
            sx={{ width: '100%', display: 'flex', 
            alignItems: 'center' }}>
              <div style={{width: '100%',}}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {/* Main Title */}
                  <Typography variant="h6" 
                  sx={{ fontWeight: 'bold' , paddingRight:'6px'}}>
                    {(priceOptions?.nameT ?? {})['en']} 
                  </Typography>

                </Box>
                
              
                  {(priceOptions?.addOns??[]).map((addOn, addOnIndex) => (
                  <Box key={addOn?.uuid??""} sx={{ display: 'flex', alignItems: 'center' }}>
                    
                    <Checkbox
                      checked={addOn?.selected??false}
                      onChange={() => handlePriceOption(addOnIndex)}
                      name={(addOn.nameT??{})['en']}
                    />
                    <Typography style={{flex:1}}>{(addOn?.nameT??{})['en']}</Typography>
                    <Typography>{(addOn?.price??0)/100} {storeModel?.currency??"LE"}</Typography>
                  </Box>
                ))}
                <Divider />
              {/* <Divider
                  sx={{ width: '100%', height: '.01px', backgroundColor: 'gray' }}
                /> */}

              </div>
            </Box>
          ) : (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* Main Title */}
            <Typography variant="h6" 
            sx= {{ fontWeight: 'bold' , paddingRight:'6px'}}>
              Price : {((product?.price??0)/100)} 
            </Typography>

          </Box>)}


          {/* The read AddOn Options */}
          {addOnOptionsL.map((addOnOption, optionIndex) => (
            <Box key={addOnOption?.uuid??""} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              
              <div style={{width: '100%',}}>
                
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {/* Main Title */}
                  <Typography variant="h6" 
                  sx={{ fontWeight: 'bold' , paddingRight:'6px'}}>
                    {(addOnOption?.nameT ?? {})['en']} 
                  </Typography>

                  {/* Max Text */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: '#FFD31A',
                    }}
                  >
                    (   Max {addOnOption?.max} {addOnOption?.must == true ? "Required" : ""}  )
                  </Typography>
                </Box>
                
              
                  {(addOnOption.addOns??[]).map((addOn, addOnIndex) => (
                  <Box key={addOn?.uuid??""} sx={{ display: 'flex', alignItems: 'center' }}>
                    
                    <Checkbox
                      checked={addOn?.selected??false}
                      
                      onChange={() => handleAddOnChange(optionIndex, addOnIndex)}

                      name={(addOn.nameT??{})['en']}
                    />
                    {/* <Typography>{(addOn?.nameT??{})['en']}</Typography> */}
                    <Typography style={{flex:1}}>{(addOn?.nameT??{})['en']}</Typography>
                    {(addOn?.price??0) > 0 && (<Typography>{(addOn?.price??0)/100} {storeModel?.currency??"LE"}</Typography>)}
                  </Box>
                ))}
                <Divider />
              {/* <Divider
                  sx={{ width: '100%', height: '.01px', backgroundColor: 'gray' }}
                /> */}

              </div>
            </Box>
          ))}
          <div style={{height: '100%',}}></div>
          {/* Note Field */}
          
          <TextField
            label="Note"
            multiline
            rows={1}
            value={note}
            onChange={handleNoteChange}
            variant="outlined"
            fullWidth
            sx={{ marginTop: 2, marginBottom: 2 }}
          />

<Box sx={{
            display: 'column',
            position: 'sticky', 
            bottom: 0, 
            width: '100%', 
            padding: '0px', 
            backgroundColor: 'white' ,
            }}>
          {/* Add to Cart Button */}
          <Box sx={{
            display: 'flex',
            // position: 'sticky', 
            bottom: 0, 
            width: '100%', 
            // padding: '0px', 
            paddingBottom:'4px',
            backgroundColor: 'white' ,
            }}>

          <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: quantity > 0 ? 'rgba(0,0,255,0.05)' : 'transparent',
                borderRadius: '12px',
              }}
            >
              { (
                <>
                  <IconButton 
                  onClick={handleRemove}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography sx={{ padding: '0 10px' }}>{quantity}</Typography>
                  <IconButton 
                  onClick={handleAdd}
                  >
                    <AddIcon />
                  </IconButton>
                </>
              ) }
              
            </Box>

            
            <Button
            onClick={() => handleAddToCart({item:product}) }
              variant="contained"
              color="primary"
              fullWidth
            >
            Add to Cart - {(product?.totalPriceF??0) / 100} {storeModel?.currency ?? 'LE'}
          </Button>
          
        </Box>

        
        {/* Quantity on Cart  */}
        <Box 
          fullWidth
          sx={{
            
            display: 'flex',
            position: 'sticky', 
            bottom: 0, 
            width: '100%', 
            // padding: '2px', 
            
            backgroundColor: '#FFD31A' ,
            }}>
           {availableQuantity() > 0 && (
            <Button
            variant="contained"
            color="#FFD31A"
            fullWidth
            
        onClick={() => handleOpenCart() }
        // variant="contained"
        
        >
        {availableQuantity()??0} 
            {" Added - Go To Cart"}
        
      </Button>
           )}
        
        
        </Box>
</Box>
      

        </Grid>
      </Grid>
    );
  
  };

  export default ProductPage